import React, { useCallback, useState, createContext, useContext, useEffect } from 'react';
import { PaymentMethod } from '@Types/cart/PaymentMethod';
import { ShippingMethod } from '@Types/cart/ShippingMethod';
import { ShippingMethodMapping } from '@Types/cart/ShippingMethodMapping';
import { CheckoutAddress, CheckoutDataContext } from '../types';

const EMPTY_ADDRESS: CheckoutAddress = {
  addressId: '',
  salutation: '',
  firstName: '',
  lastName: '',
  streetName: '',
  streetNumber: '',
  additionalStreetInfo: '',
  additionalAddressInfo: '',
  company: '',
  postalCode: '',
  city: '',
  country: '',
  state: '',
  phone: '',
  source: '',
};

const initialState: CheckoutDataContext = {
  email: '',
  billingSameAsShipping: true,
  deliveryCountry: '',

  billingAddress: EMPTY_ADDRESS,
  shippingAddress: EMPTY_ADDRESS,

  selectedPaymentMethod: '',
  selectedShippingMethods: {},

  shippingMethods: [],
  paymentMethods: [],
  shippingMethodMapping: null,

  submitting: false,
  processing: false,

  lastCheckoutError: false,

  setEmail: (email: string) => {},
  setBillingAddress: (address: CheckoutAddress) => {},
  setShippingAddress: (address: CheckoutAddress) => {},
  setBillingSameAsShipping: (same: boolean) => {},
  setSelectedPaymentMethod: (id: string) => {},
  setSelectedShippingMethod: (id: string, itemId: string) => {},
  setPaymentMethods: (paymentMethods: PaymentMethod[]) => {},
  setLastCheckoutError: (checkoutError: Error) => {},
  setShippingMethods: (shippingMethods: ShippingMethod[]) => {},
  setShippingMethodMapping: (mapping: ShippingMethodMapping) => {},
  setIsSubmitting: (submitting: boolean) => {},
  setIsProcessing: (processing: boolean) => {},
};

export const CheckoutContext = createContext(initialState);

const CheckoutProvider: React.FC = ({ children }) => {
  const [email, setStateEmail] = useState('');
  const [billingAddress, setStateBillingAddress] = useState<CheckoutAddress>(EMPTY_ADDRESS);
  const [shippingAddress, setStateShippingAddress] = useState<CheckoutAddress>(EMPTY_ADDRESS);
  const [billingSameAsShipping, setStateBillingSameAsShipping] = useState(true);
  const [deliveryCountry, setStateDeliveryCountry] = useState('');
  const [selectedPaymentMethod, setStateSelectedPaymentMethod] = useState('');
  const [lastCheckoutError, setStateLastCheckoutError] = useState<Error | false>(false);
  const [selectedShippingMethods, setStateSelectedShippingMethods] = useState({});
  const [shippingMethods, setStateShippingMethods] = useState([]);
  const [paymentMethods, setStatePaymentMethods] = useState([]);
  const [shippingMethodMapping, setStateShippingMethodMapping] = useState(null);
  const [submitting, setStateIsSubmitting] = useState(false);
  const [processing, setStateIsProcessing] = useState(true);

  const setEmail = useCallback((email: string) => setStateEmail(email || ''), []);
  const setBillingAddress = useCallback((address: CheckoutAddress) => setStateBillingAddress(address || {}), []);
  const setShippingAddress = useCallback((address: CheckoutAddress) => setStateShippingAddress(address || {}), []);
  const setBillingSameAsShipping = useCallback(
    (same: boolean) => setStateBillingSameAsShipping(same === false ? false : true),
    [],
  );
  const setSelectedPaymentMethod = useCallback((id: string) => setStateSelectedPaymentMethod(id || ''), []);
  const setLastCheckoutError = useCallback(
    (checkoutError: Error) => setStateLastCheckoutError(checkoutError || false),
    [],
  );
  const setIsSubmitting = useCallback((submitting: boolean) => setStateIsSubmitting(submitting || false), []);
  const setIsProcessing = useCallback((processing: boolean) => setStateIsProcessing(processing || false), []);

  const setSelectedShippingMethod = useCallback((id: string, itemId: string) => {
    setStateSelectedShippingMethods((prevState) => ({
      ...prevState,
      [itemId]: id,
    }));
  }, []);

  useEffect(() => {
    if (shippingAddress?.country && shippingAddress.country !== deliveryCountry) {
      setStateDeliveryCountry(shippingAddress.country);
    }
  }, [billingSameAsShipping, billingAddress, shippingAddress]);

  useEffect(() => {
    setStateLastCheckoutError(null);
  }, [selectedPaymentMethod]);

  const setShippingMethods = useCallback((shippingMethods: ShippingMethod[]) => {
    setStateShippingMethods(shippingMethods);
  }, []);

  const setPaymentMethods = useCallback((paymentMethods: PaymentMethod[]) => {
    setStatePaymentMethods(paymentMethods);
  }, []);

  const setShippingMethodMapping = useCallback((mapping: ShippingMethodMapping) => {
    setStateShippingMethodMapping(mapping);
  }, []);

  const value = {
    email,
    billingAddress,
    shippingAddress,
    billingSameAsShipping,
    deliveryCountry,
    selectedPaymentMethod,
    lastCheckoutError,
    selectedShippingMethods,
    shippingMethods,
    paymentMethods,
    shippingMethodMapping,
    submitting,
    processing,
    setEmail,
    setBillingAddress,
    setShippingAddress,
    setBillingSameAsShipping,
    setSelectedPaymentMethod,
    setLastCheckoutError,
    setSelectedShippingMethod,
    setShippingMethods,
    setPaymentMethods,
    setShippingMethodMapping,
    setIsSubmitting,
    setIsProcessing,
  };

  return <CheckoutContext.Provider value={value}>{children}</CheckoutContext.Provider>;
};

export default CheckoutProvider;

export const useCheckout = () => {
  return useContext(CheckoutContext);
};
