import React, { Fragment, useState } from 'react';
import NextLink from 'next/link';
import { Popover, Transition } from '@headlessui/react';
import { MenuIcon } from '@heroicons/react/outline';
import { Account } from '@Types/account/Account';
import { Category } from '@Types/navigation/Category';
import { Reference, ReferenceLink } from 'helpers/reference';
import Image, { MediaItemWithMods } from 'frontastic/lib/image';
import Typography from '../../commercetools-ui/typography';
import AccountButton from './buttons/account-button';
import CartButton from './buttons/cart-button';
import SearchButton from './buttons/search-button';
import WishListButton from './buttons/wishlist-button';
import CategoryMenu from './category-menu';
import MobileMenu from './mobile-menu';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export interface HeaderProps {
  tagline?: string;
  categories?: Category[];
  cartItemCount?: number;
  wishlistItemCount?: number;
  logo?: MediaItemWithMods;
  logoLink?: Reference;
  account?: Account;
  accountLink?: Reference;
  wishlistLink?: Reference;
  cartLink?: Reference;
  hideWishlistButton?: boolean;
  previewId?: string;
}

const Header: React.FC<HeaderProps> = ({
  tagline,
  categories,
  cartItemCount,
  wishlistItemCount,
  logo,
  logoLink,
  account,
  accountLink,
  wishlistLink,
  cartLink,
  hideWishlistButton = true,
  previewId,
}) => {
  const [open, setOpen] = useState(false);

  const categoryMenuClassnames = {
    heading: 'font-medium text-gray-900',
    link: 'hover:text-gray-800',
    list: 'ml-4 mt-6 space-y-6 sm:mt-4 sm:space-y-4',
    item: 'flex',
  };

  return (
    <div className="fixed-screen-width lg:relative-width">
      <MobileMenu open={open} setOpen={setOpen} categories={categories} />

      <header aria-label="header" className="relative bg-neutral-100">
        {tagline && (
          <p className="flex items-center justify-center bg-primary-400 px-4 text-sm font-medium text-white sm:px-6 lg:px-8">
            <Typography>{tagline}</Typography>
          </p>
        )}

        <nav aria-label="Top" className="mx-auto max-w-full px-6 pl-2 shadow-header lg:px-8 lg:pl-15">
          <div className="h-full">
            <div className="flex h-auto items-center justify-between">
              <div className="flex flex-1 grow-0 items-center lg:hidden">
                <button
                  type="button"
                  className="rounded-md bg-none p-2 text-primary-400 dark:text-light-100"
                  onClick={() => setOpen(!open)}
                >
                  <span className="sr-only">Open menu</span>
                  <MenuIcon className="h-7 w-7 text-primary-400" aria-hidden="true" />
                </button>
              </div>

              {/* Logo */}
              <ReferenceLink
                target={logoLink}
                className="flex h-full flex-auto items-center py-2 pr-2 md:flex-initial md:py-3"
              >
                <div className="relative h-12 w-[100px] pr-3 sm:w-[160px] sm:pr-7">
                  <Image media={logo} className="dark:invert" layout="fill" objectFit="contain" alt="Logo" />
                </div>
              </ReferenceLink>

              <Popover.Group className="z-20 hidden justify-center lg:flex lg:flex-1 lg:self-stretch">
                <div className="flex h-full space-x-24">
                  {categories.map((category, categoryIdx) => (
                    <>
                      {category.children.length > 0 ? (
                        <Popover key={`menu-${categoryIdx}-${category.categoryId}`} className="flex">
                          {({ open }) => (
                            <>
                              <div className="relative flex">
                                <Popover.Button
                                  className={classNames(
                                    open ? 'border-primary-400' : 'border-transparent hover:border-primary-400',
                                    'relative z-10 -mb-px flex items-center border-b-4 pt-px text-sm font-medium uppercase text-primary-400 transition-colors duration-200 ease-out',
                                  )}
                                >
                                  <Typography>{category.name}</Typography>
                                </Popover.Button>
                              </div>

                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="transition ease-in duration-15000"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Popover.Panel className="absolute inset-x-0 top-full z-10 text-gray-500 sm:text-sm">
                                  {({ close }) => (
                                    <>
                                      <div className="absolute inset-0 top-1/2 bg-white shadow" aria-hidden="true" />
                                      <div className="relative bg-white">
                                        <div className="flex basis-full bg-primary-400 p-3.75 px-6 text-font-default lg:px-15">
                                          {category.path ? (
                                            <NextLink href={category.path}>
                                              <a className="font-semibold text-white" onClick={close as never}>
                                                Alle <Typography>{category.name}</Typography> anzeigen
                                              </a>
                                            </NextLink>
                                          ) : (
                                            <Typography>{category.name}</Typography>
                                          )}
                                        </div>
                                        <div className="grid grid-cols-header gap-x-13.75 gap-y-10 bg-neutral-100 px-6 py-10 text-font-default lg:px-15">
                                          <CategoryMenu
                                            category={category}
                                            categoryIndex={categoryIdx}
                                            mode="desktop"
                                            closeMenu={close}
                                            classNames={categoryMenuClassnames}
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </Popover.Panel>
                              </Transition>
                            </>
                          )}
                        </Popover>
                      ) : (
                        <NextLink href={category.path}>
                          <a
                            href={category.path}
                            className="relative z-10 -mb-px flex items-center border-b-4 border-transparent pt-px text-sm font-medium uppercase text-primary-400 transition-colors duration-200 ease-out"
                          >
                            <Typography>{category.name}</Typography>
                          </a>
                        </NextLink>
                      )}
                    </>
                  ))}
                </div>
              </Popover.Group>

              <div className="flex flex-initial items-center justify-end">
                <div className="flex w-fit items-center">
                  <SearchButton />
                  <AccountButton account={account} accountLink={accountLink} />
                  {!hideWishlistButton && (
                    <WishListButton wishlistItemCount={wishlistItemCount} wishlistLink={wishlistLink} />
                  )}
                  <CartButton cartItemCount={cartItemCount} cartLink={cartLink} />
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Header;
