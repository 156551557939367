import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { Address } from '@Types/account/Address';
import { Payment } from '@Types/cart/Payment';
import { mutate } from 'swr';
import Spinner from 'components/commercetools-ui/spinner';
import { useFormat } from 'helpers/hooks/useFormat';
import { Reference } from 'helpers/reference';
import { useAccount, useCart } from 'frontastic';
import { CartDetails, updateItemShippingMethods } from '../../../frontastic/actions/cart';
import { TagManager } from '../../../frontastic/lib/tag-manager';
import EmptyCart from '../cart/empty-cart';
import { CartError } from '../cart/errors/CartError';
import { OrderErrorProps } from '../order-error';
import CheckoutErrorList from './checkout-error/checkout-error-list';
import CheckoutForm, { CountryOptionItem } from './checkout-form';
import { CheckoutValidationError } from './errors/CheckoutValidationError';
import { PaymentResponseError } from './errors/PaymentResponseError';
import { useCheckout } from './provider';
import { CheckoutAddress } from './types';
import { PaymentHandler } from './utils/PaymentHandler';
import { ShippingHandler } from './utils/ShippingHandler';
import { isCheckoutDataValid, isItemsListValid } from './validation';

interface Props {
  loginLink?: Reference;
  billingCountryOptions?: CountryOptionItem[];
  shippingCountryOptions?: CountryOptionItem[];
  orderErrorData?: OrderErrorProps;
  paymentDescriptionList?: [];
  paymentPreselection?: string;
  deliveryTime?: string;
}

const Checkout = ({
  billingCountryOptions,
  shippingCountryOptions,
  orderErrorData,
  paymentDescriptionList,
  paymentPreselection,
  deliveryTime,
}: Props) => {
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
  const { account, loggedIn } = useAccount();

  const router = useRouter();
  const checkoutData = useCheckout();
  const {
    data,
    getPaymentMethods,
    getShippingMethods,
    getShippingMethodMapping,
    setPaymentMethod,
    updateCart,
    refreshCart,
    triggerPayment,
    syncCartPayment,
  } = useCart();

  const [activePaymentMethod, setActivePaymentMethod] = useState<Payment | null>(null);
  const [paymentSyncExecuted, setPaymentSyncExecuted] = useState<boolean>(false);
  const [checkoutErrors, setCheckoutErrors] = useState<Error[]>([]);
  const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
  const [returnedFromPsp, setReturnedFromPsp] = useState<boolean>(false);
  const [checkStatus, setCheckStatus] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const currentPayment = useRef<Payment | null>();

  const submitForm = async () => {
    let isRedirecting = false;

    checkoutData.setIsSubmitting(true);
    window.scrollTo(0, 0);

    setCheckoutErrors([]);
    setReturnedFromPsp(false);

    try {
      await updateCartDetails();
      await setPaymentMethod(checkoutData.selectedPaymentMethod);
      await updateItemShippingMethods(
        data?.lineItems.map((item) => ({
          ...item,
          shippingDetails: {
            shippingMethodId: checkoutData.selectedShippingMethods[item.lineItemId],
            shippingAddress: getItemShippingAddress(),
          },
        })),
      );

      const payment = await triggerPayment();
      const redirectUrl = PaymentHandler.getCheckoutUrl(payment);

      if (redirectUrl) {
        if (redirectUrl === router.query['path'] || redirectUrl === router.asPath) {
          setRefreshRequired(true);
        } else {
          isRedirecting = true;
          await router.push(redirectUrl);
        }
      } else {
        checkoutData.setLastCheckoutError(new PaymentResponseError('Failed to initialize payment'));
      }
    } catch (error: any) {
      mutate('/action/cart/getCart');

      checkoutData.setLastCheckoutError(
        error instanceof CartError ? error : PaymentHandler.getCheckoutResponseError(error),
      );
    } finally {
      checkoutData.setIsSubmitting(isRedirecting);
    }
  };

  // Note: the shipping address is the primary address, so we have to use it in case of "billingSameAsShipping"
  const getBillingDetails = () => {
    if (!loggedIn) {
      return checkoutData.billingSameAsShipping ? checkoutData.shippingAddress : checkoutData.billingAddress;
    }

    const addressId = checkoutData.billingSameAsShipping
      ? checkoutData.shippingAddress.addressId
      : checkoutData.billingAddress.addressId;
    const address = account.addresses.find((address) => address.addressId === addressId);

    return {
      ...address,
      phone: checkoutData.shippingAddress.phone || checkoutData.billingAddress.phone,
      source: checkoutData.billingAddress.source ?? 'checkout',
    } as CheckoutAddress;
  };

  const updateCartDetails = async () => {
    const payload: CartDetails = {
      account: {
        email: checkoutData.email,
      },
      billing: getBillingDetails(),
      payment: checkoutData.selectedPaymentMethod,
    };

    if (data.shippingMode === 'Single') {
      payload.shipping = loggedIn
        ? account.addresses.find((address) => address.addressId === checkoutData.shippingAddress.addressId)
        : !checkoutData.billingSameAsShipping
        ? checkoutData.shippingAddress
        : null;
    }

    await updateCart(payload);
  };

  const initCheckoutData = () => {
    const billingAddress = getInitAddress('billing');
    const shippingAddress = getInitAddress('shipping');
    const isEmptyShippingAddress = ShippingHandler.isEmptyAddress(shippingAddress, ['country']);
    const billingSameAsShipping =
      ShippingHandler.isSameAddress(billingAddress, shippingAddress) || isEmptyShippingAddress;

    billingAddress.phone = billingAddress.phone ?? shippingAddress.phone;
    shippingAddress.phone = shippingAddress.phone ?? billingAddress.phone;

    checkoutData.setEmail(data?.email || account?.email || '');
    checkoutData.setBillingSameAsShipping(billingSameAsShipping);
    checkoutData.setBillingAddress(billingAddress);
    checkoutData.setShippingAddress(billingSameAsShipping && isEmptyShippingAddress ? billingAddress : shippingAddress);
  };

  const isValidInitAddress = (address: Address) => {
    return !ShippingHandler.isEmptyAddress(address, ['country']) && !loggedIn === !address.addressId;
  };

  const getCartAddress = (type: string) => {
    if (!data) {
      return undefined;
    }

    const address = type !== 'shipping' ? data?.billingAddress : ShippingHandler.getCartShippingAddress(data);
    if (!address || !isValidInitAddress(address)) {
      return undefined;
    }
    return { ...address, country: address.country || 'DE', source: 'cart' };
  };

  const getCheckoutDataAddress = (type: string) => {
    const address = type === 'shipping' ? checkoutData.shippingAddress : checkoutData.billingAddress;
    if (!address || !isValidInitAddress(address)) {
      return undefined;
    }
    return { ...address, country: address.country || 'DE', source: 'cart' };
  };

  const getAccountAddress = (type: string) => {
    if (loggedIn) {
      const address =
        account?.addresses?.find((address) =>
          type !== 'shipping' ? address.isDefaultBillingAddress : address.isDefaultShippingAddress,
        ) ?? account?.addresses?.find((address) => address !== undefined);

      if (address && isValidInitAddress(address)) {
        return { ...address, country: address.country || 'DE', source: 'account' };
      }
    }
    return undefined;
  };

  const getInitAddress = (type: string) => {
    return (
      getCheckoutDataAddress(type) ??
      getCartAddress(type) ??
      getAccountAddress(type) ?? {
        firstName: account?.firstName || '',
        lastName: account?.lastName || '',
        phone: '',
        streetName: '',
        streetNumber: '',
        postalCode: '',
        city: '',
        country: 'DE',
        source: 'init',
      }
    );
  };

  // Note: the shipping address is the primary address
  const getItemShippingAddress = () => {
    if (!loggedIn) {
      return checkoutData.shippingAddress;
    }

    const addressId = checkoutData.shippingAddress?.addressId;
    if (addressId) {
      return account.addresses.find((address) => address.addressId === addressId) || null;
    }

    return null;
  };

  const updatePaymentMethod = (paymentMethod) => {
    const refPayment = currentPayment.current || null;
    if (refPayment === null || refPayment.id !== paymentMethod.id) {
      changePaymentMethod(paymentMethod);
    }
  };

  const changePaymentMethod = (paymentMethod) => {
    if (!checkoutData.submitting) {
      setCheckStatus(true);
      setActivePaymentMethod(paymentMethod);
    }
  };

  const isCheckoutDataProcessed = () => {
    return !!data?.cartId && !!data?.payments;
  };

  const getUnresolvedPaymentError = (payment: Payment) => {
    const message = payment.paymentMethod; //orderErrorData?.text && orderErrorData.text.length ? orderErrorData.text : payment.paymentId;
    return new PaymentResponseError(`Unresolved payment - ${message}`);
  };

  const processPayment = (payment: Payment): void => {
    switch (true) {
      case PaymentHandler.isPaymentSettled(payment):
        setCheckStatus(true);
        router.push('/thank-you');
        break;
      case PaymentHandler.isPaymentUnresolved(payment):
        checkoutData.setLastCheckoutError(getUnresolvedPaymentError(payment));
        setRefreshRequired(true);
        break;
      case PaymentHandler.isPaymentFailed(payment):
        checkoutData.setLastCheckoutError(new PaymentResponseError(''));
        setCheckStatus(false);
        break;
      default:
        setCheckStatus(false);
    }
  };

  useEffect(() => setCheckStatus(true), []);

  useEffect(() => {
    if (checkoutData.submitting) {
      return;
    }

    initCheckoutData();

    checkoutData.setIsProcessing(!isCheckoutDataProcessed());
  }, [data?.cartId, data?.billingAddress, data?.shippingAddress]);

  useEffect(() => {
    if (!data?.payments) {
      return;
    }

    const cartPaymentMethod = PaymentHandler.getMolliePayment(data);
    if (!cartPaymentMethod) {
      // Check, if we have a failed payment, in this case we should show an error message
      const lastPaymentMethod = PaymentHandler.getMolliePayment(data, true);
      if (!!lastPaymentMethod) {
        processPayment(lastPaymentMethod);
        setCheckStatus(false);
      }
      return;
    }

    // Notification from mollie may take some time, so if we return from mollie and the payment is still pending we
    // try "refresh" the payment details (hoping mollie sent the required update in a reasonable time frame)
    if (returnedFromPsp && !paymentSyncExecuted && PaymentHandler.isPaymentPending(cartPaymentMethod)) {
      setCheckStatus(true);

      const synchronize = async () => {
        try {
          await syncCartPayment();
        } catch (error: any) {
          mutate('/action/cart/getCart');
          setCheckoutErrors([]);

          updatePaymentMethod(cartPaymentMethod);
          checkoutData.setLastCheckoutError(PaymentHandler.getCheckoutResponseError(error));
          checkoutData.setIsProcessing(!isCheckoutDataProcessed());
        } finally {
          setPaymentSyncExecuted(true);
          setReturnedFromPsp(false);
        }
      };

      synchronize();
      return;
    }

    updatePaymentMethod(cartPaymentMethod);
    checkoutData.setIsProcessing(!isCheckoutDataProcessed());
  }, [data?.payments]);

  useEffect(() => {
    if (!data?.cartId || checkoutData.submitting) {
      return;
    }

    if (!PaymentHandler.cartRequiresPayment(data)) {
      checkoutData.setPaymentMethods([]);
      return;
    }

    (async () => {
      setLoading(true);
      const paymentMethods = await getPaymentMethods();
      checkoutData.setPaymentMethods(paymentMethods);
      setLoading(false);
    })();
  }, [data?.sum?.centAmount]);

  useEffect(() => {
    if (!data?.cartId || checkoutData.submitting) {
      return;
    }

    (async () => {
      const shippingMethods = await getShippingMethods();
      const shippingMethodMapping = await getShippingMethodMapping();

      checkoutData.setShippingMethods(shippingMethods);
      checkoutData.setShippingMethodMapping(shippingMethodMapping);
    })();
  }, [data?.lineItems, data?.billingAddress, data?.shippingAddress]);

  useEffect(() => {
    currentPayment.current = activePaymentMethod;
    if (!!activePaymentMethod) {
      processPayment(activePaymentMethod);
      return;
    }

    if (PaymentHandler.isRedirectFromMollie(router.query)) {
      const { method } = router.query;
      if (PaymentHandler.isNonInteractiveType(method)) {
        router.push({ pathname: '/thank-you', query: { method: method } });
        return;
      }
    }
    setCheckStatus(false);
  }, [activePaymentMethod]);

  useEffect(() => {
    if (refreshRequired) {
      refreshCart().then(() => {
        changePaymentMethod(null);
        setRefreshRequired(false);
      });
    }
  }, [refreshRequired, refreshCart]);

  useEffect(() => {
    if (checkoutData.lastCheckoutError) {
      const errorList = checkoutData.lastCheckoutError instanceof CheckoutValidationError ? [] : checkoutErrors;
      const inList = errorList.find(
        (error) => checkoutData.lastCheckoutError !== false && error.message === checkoutData.lastCheckoutError.message,
      );

      if (!inList) {
        errorList.push(checkoutData.lastCheckoutError);
      }

      setCheckoutErrors(errorList);
      window.scrollTo(0, 0);
    }
  }, [checkoutData.lastCheckoutError]);

  useEffect(() => {
    setReturnedFromPsp(PaymentHandler.isRedirectFromMollie(router.query));
    if (!!data) {
      new TagManager().beginCheckoutEvent(data).executePush();
    }
  }, [data?.cartId]);

  return (
    <main className="lg:py-10">
      <h1 className="sr-only">{formatCartMessage({ id: 'checkout', defaultMessage: 'Checkout' })}</h1>
      {loading || checkStatus || checkoutData.processing || checkoutData.submitting ? (
        <div className="flex w-full items-stretch justify-center py-10 px-12">
          <Spinner />
        </div>
      ) : data.lineItems && data.lineItems.length > 0 ? (
        <>
          <CheckoutErrorList errors={checkoutErrors} />
          <CheckoutForm
            submitText={`${formatCheckoutMessage({
              id: 'pay',
              defaultMessage: 'Pay',
            })}`}
            submitForm={submitForm}
            isFormValid={isItemsListValid(data) && isCheckoutDataValid(checkoutData, loggedIn)}
            isGuestCheckout={!loggedIn}
            addressList={account?.addresses || []}
            billingCountryOptions={billingCountryOptions || []}
            shippingCountryOptions={shippingCountryOptions || billingCountryOptions || []}
            paymentDescriptionList={paymentDescriptionList}
            paymentPreselection={paymentPreselection}
            deliveryTime={deliveryTime}
          />
        </>
      ) : (
        <EmptyCart cartErrors={checkoutErrors.filter((error) => error instanceof CartError)} />
      )}
    </main>
  );
};

export default Checkout;
