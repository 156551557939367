import { Cart } from '@Types/cart/Cart';
import { Product } from '@Types/product/Product';
import { Variant } from '@Types/product/Variant';
import { EcommerceDataLayer } from '@Types/tagmanager/EcommerceDataLayer';
import GoogleTagManager from 'react-gtm-module-custom-domain';
import { DataLayerFactory } from './dataLayerFactory';

export class TagManager {
  private dataLayerId: string;
  private ecommerceLayers: EcommerceDataLayer[];

  constructor(dataLayerId = 'dataLayer') {
    this.dataLayerId = dataLayerId;
    this.ecommerceLayers = [];
  }

  executePush(): void {
    while (this.ecommerceLayers.length) {
      this.pushEcommerceLayer(this.ecommerceLayers.shift());
    }
  }

  addToCartEvent(product: Product, variant: Variant, quantity?: number, payload?: any): this {
    this.ecommerceLayers.push(DataLayerFactory.getAddToCartLayer(product, variant, quantity, payload));
    return this;
  }

  beginCheckoutEvent(cart: Cart): this {
    this.ecommerceLayers.push(DataLayerFactory.getBeginCheckoutLayer(cart));
    return this;
  }

  purchaseEvent(cart: Cart): this {
    this.ecommerceLayers.push(DataLayerFactory.getPurchaseLayer(cart));
    return this;
  }

  private pushEcommerceLayer(dataLayer: EcommerceDataLayer): void {
    GoogleTagManager.dataLayer(this.getGtmLayer(DataLayerFactory.getClearEcommerceLayer()));
    GoogleTagManager.dataLayer(this.getGtmLayer(dataLayer));
  }

  private getGtmLayer(dataLayer: any) {
    return { dataLayerName: this.dataLayerId, dataLayer };
  }
}
