import React, { FC, FormEvent, useEffect } from 'react';
import { Product } from '@Types/product/Product';
import { Facet } from '@Types/result/Facet';
import { TermFacet } from '@Types/result/TermFacet';
import { useFormat } from 'helpers/hooks/useFormat';
import { URLParam } from 'helpers/utils/updateURLParams';
import { useFilter } from '../../../frontastic';
import PriceFilterDisclosure from './price-filter-disclosure';
import SortingDisclosure from './sorting-disclosure';
import TermFilterList from './term-filter-list';

type FiltersProps = {
  facets: Facet[];
  products: Product[];
  visibleFilters?: string[];
  closeFilters?: () => void;
};

const Filters: FC<FiltersProps> = ({ facets, products, visibleFilters = [], closeFilters }) => {
  const { formatMessage } = useFormat({ name: 'product' });
  const { setFilter, submitFilter } = useFilter();

  const setTermFilteringParams = (termFilteringParams: URLParam[]) => {
    setFilter((filter) => ({
      ...filter,
      termFilteringParams,
    }));
  };

  const setPriceFilteringParams = (priceFilteringParams: URLParam[]) => {
    setFilter((filter) => ({
      ...filter,
      priceFilteringParams,
    }));
  };

  const setSortingParam = (sortingParam: URLParam) => {
    setFilter((filter) => ({
      ...filter,
      sortingParam,
    }));
  };

  useEffect(() => {
    setTermFilteringParams(
      (facets.filter(({ type }) => type === 'term') as TermFacet[]).reduce((arr, facet) => {
        facet.terms
          .filter(({ selected }) => selected)
          .forEach(({ label }, index) => {
            arr.push({
              key: `facets[${facet.key}][terms][${index.toString()}]`,
              value: label,
            });
          });

        return arr;
      }, []),
    );
  }, [facets]);

  const updatePriceFilteringParams = (params: URLParam[]) => {
    setPriceFilteringParams(params);
  };

  const updateSortingParams = (param: URLParam) => {
    setSortingParam(param);
  };

  const updateTermFilteringParams = (params: URLParam[]) => {
    setTermFilteringParams(params);
  };

  const handleFiltersSubmit = (event?: FormEvent) => {
    event?.preventDefault?.();

    submitFilter();

    closeFilters?.();
  };

  return (
    <form onSubmit={handleFiltersSubmit} className="mb-5">
      <SortingDisclosure updateSortingParams={updateSortingParams} />
      <PriceFilterDisclosure
        products={products}
        facets={facets}
        updatePriceFilteringParams={updatePriceFilteringParams}
      />
      <TermFilterList
        facets={facets}
        visibleFilters={visibleFilters.map((filter) => filter.toLowerCase())}
        updateTermFilteringParams={updateTermFilteringParams}
      />
      <div className="sticky bottom-0 mt-6 bg-white py-2">
        <button type="submit" className="w-full rounded bg-primary-400 py-2.5 font-semibold text-white">
          {formatMessage({ id: 'applyFilters', defaultMessage: 'Apply filters' })}
        </button>
      </div>
    </form>
  );
};

export default Filters;
