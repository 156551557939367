import React from 'react';
import ReveloUspItem, { ItemConfig as UspItemConfig } from '../usp-item';

export interface UspBarProps {
  items: UspItemConfig[];
  className?: string;
}

const ReveloUspBar: React.FC<UspBarProps> = ({ items, className }) => {
  if (items.length > 0) {
    return (
      <>
        {/* eslint-disable-next-line */}
        <div className={ className ? className : '' }>
          {items.map((item: UspItemConfig, index: number) => {
            if (item.name && item.name.length > 0) {
              item.key = item.key || index.toString();
              return <ReveloUspItem item={item} key={index} />;
            }
          })}
        </div>
      </>
    );
  }
};

export default ReveloUspBar;
