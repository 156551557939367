import React, { useCallback, useEffect, useRef } from 'react';
import { Term } from '@Types/result/Term';
import { TermFacet } from '@Types/result/TermFacet';
import { useFilter } from '../../../frontastic';

export type TermFilterParams = {
  index: number;
  value: string;
};

type TermFilterDisclosureProps = {
  facet: TermFacet;
};

const termSortMapping = {
  XXS: -16,
  XS: -15,
  S: -14,
  'S/M': -13,
  M: -12,
  'M/L': -11,
  L: -10,
  'L/XL': -9,
  XL: -8,
  XXL: -7,
  S2: -6,
  S3: -5,
  S4: -4,
  S5: -3,
  cm: -2,
  in: -1,
};

const mapTerm = ({ label }: Term) => {
  const weight = termSortMapping[label.toUpperCase()];

  if (weight) {
    return { weight };
  } else if (label.toLowerCase().includes('cm')) {
    return {
      weight: termSortMapping.cm,
      value: parseInt(label, 10),
    };
  } else if (label.includes('"')) {
    return {
      weight: termSortMapping.in,
      value: parseInt(label, 10),
    };
  }

  return undefined;
};

type TermItemProps = {
  term: Term;
  index: number;
  handleChange: (index: number, checked: boolean) => void;
  selected: Record<string, boolean>;
};

const TermItem = ({ term, index, handleChange, selected }: TermItemProps) => {
  const checkbox = useRef<HTMLInputElement>(null);
  const handleClick = useCallback(() => {
    checkbox.current?.click();
  }, []);

  return (
    <div className="relative flex items-start" key={term.identifier}>
      <div className="flex h-5 items-center">
        <input
          type="checkbox"
          className="h-5 w-5 rounded border-gray-300 text-transparent"
          onChange={(e) => handleChange(index, e.target.checked)}
          checked={selected[term.key] ?? false}
          ref={checkbox}
        />
      </div>
      <div className="ml-3 text-sm" onClick={handleClick}>
        <label htmlFor="comments" className="font-medium text-gray-700 dark:text-light-100">
          {term.label}
        </label>
      </div>
    </div>
  );
};

const TermFilter: React.FC<TermFilterDisclosureProps> = ({ facet }) => {
  const { filter, setFilter } = useFilter();
  const { params, selected } = filter.terms[facet.key] ?? { params: [], selected: {} };

  useEffect(() => {
    setFilter((filter) => ({
      ...filter,
      terms: {
        ...filter.terms,
        [facet.key]: {
          params:
            facet?.terms
              ?.map((term, index) => ({
                index,
                value: term.key,
                selected: term.selected,
              }))
              .filter((term) => term.selected) || [],
          selected: Object.fromEntries(facet?.terms?.map((term) => [term.key, term.selected])),
        },
      },
    }));
  }, [facet]);

  const handleChange = useCallback(
    (index: number, checked: boolean) => {
      if (!facet?.terms) return;

      let newParams = [...params];

      if (!checked) newParams = newParams.filter((param) => param.index !== index);
      else newParams = [...newParams, { index, value: facet.terms[index].key }];

      setFilter((filter) => ({
        ...filter,
        terms: {
          ...filter.terms,
          [facet.key]: {
            params: newParams,
            selected: { ...selected, [facet.terms[index].key]: checked },
          },
        },
      }));
    },
    [facet, params, selected],
  );

  facet.terms.sort((a, b) => {
    if (facet.identifier === 'variants.attributes.frame_height_manufacturer') {
      const aMap = mapTerm(a);
      const bMap = mapTerm(b);

      if (aMap && bMap) {
        if (aMap.weight === bMap.weight && aMap.value && bMap.value) {
          return aMap.value - bMap.value;
        }

        return aMap.weight - bMap.weight;
      }

      if (aMap) {
        return -1;
      }

      if (bMap) {
        return 1;
      }
    }

    if (a.label === b.label) {
      return 0;
    }

    const aNumber = parseInt(a.label, 10);
    const bNumber = parseInt(b.label, 10);
    const aIsNumber = !isNaN(aNumber);
    const bIsNumber = !isNaN(bNumber);

    if (aIsNumber && bIsNumber) {
      return aNumber - bNumber;
    }

    if (aIsNumber) {
      return -1;
    }

    if (bIsNumber) {
      return 1;
    }

    return a.label > b.label ? 1 : -1;
  });

  return (
    <div className="-m-1 flex max-h-[182px] flex-col flex-nowrap gap-2 overflow-y-auto p-1">
      {facet.terms.map((term, index) => (
        <TermItem term={term} index={index} handleChange={handleChange} selected={selected} key={index} />
      ))}
    </div>
  );
};

export default TermFilter;
