import React from 'react';
import classnames from 'classnames';
import { Reference, ReferenceLink } from '../../../../helpers/reference';

type Props = {
  type: 'primary' | 'secondary' | 'hollow';
  text: string;
  reference?: Reference;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
};

const Button: React.FC<Props> = ({ type, text, reference, onClick, className, disabled = false }) => {
  const button = (
    <button
      type="button"
      className={classnames(
        className,
        'min-w-[250px] items-center justify-center rounded-md border-2 border-transparent py-3 px-6 text-sm font-semibold transition duration-150 ease-out focus:outline-none focus:ring-2  focus:ring-offset-2',
        {
          'bg-accent-400 text-white hover:bg-accent-500 focus:bg-accent-500 focus:ring-accent-400 disabled:bg-accent-100':
            type === 'primary',
          'bg-primary-400 text-white hover:bg-primary-500 focus:bg-primary-500 focus:ring-primary-400 disabled:bg-primary-100':
            type === 'secondary',
          'border-hollow-dark bg-hollow text-hollow-text hover:border-hollow-text hover:bg-hollow-dark focus:border-hollow-text focus:bg-hollow-dark focus:ring-hollow-dark disabled:bg-hollow disabled:text-hollow-dark':
            type === 'hollow',
        },
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </button>
  );

  if (reference) {
    return <ReferenceLink target={reference}>{button}</ReferenceLink>;
  }

  return button;
};

export default Button;
