import React, { FC, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { CurrencyEuroIcon, EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import { Product } from '@Types/product/Product';
import { Facet } from '@Types/result/Facet';
import { RangeFacet } from '@Types/result/RangeFacet';
import { TwoThumbInputRange } from 'react-two-thumb-input-range';
import { useFormat } from 'helpers/hooks/useFormat';
import { URLParam } from 'helpers/utils/updateURLParams';
import Price from '../price';

type RangeInputValues = [number, number];

export type PriceRangeProps = {
  facet: RangeFacet;
  currency: string;
  onChange: (values: [number, number]) => void;
  minValue?: number;
  maxValue?: number;
};

const PriceInput = ({ value, onChange }) => (
  <div className="relative w-2/6">
    <input
      type="text"
      className="block h-6 w-full appearance-none rounded-lg border-2 border-gray-300 p-2 pr-4 text-xs focus:border-accent-400 focus:outline-none focus:ring-accent-400"
      onChange={(event) => onChange(event.target.value)}
      value={value}
    />
    <span className="absolute right-2 top-1/2 block h-3 w-3 -translate-y-1/2 text-neutral-600">
      <CurrencyEuroIcon />
    </span>
  </div>
);

const RangeFilter: FC<PriceRangeProps> = ({ facet, onChange, currency }) => {
  const router = useRouter();
  const { formatMessage } = useFormat({ name: 'product' });
  const widthRef = useRef(null);
  const [inputWidth, setInputWidth] = useState(0);
  const [minPrice, setMinPrice] = useState<number>(null);
  const [maxPrice, setMaxPrice] = useState<number>(null);
  const [values, setValues] = useState<RangeInputValues>([minPrice, maxPrice]);

  const updateValues = (updatedValues: RangeInputValues) => {
    const minValue = isNaN(updatedValues[0]) ? values[0] : +updatedValues[0];
    const maxValue = isNaN(updatedValues[1]) ? values[1] : +updatedValues[1];

    if (minValue < maxValue) {
      setValues([minValue, maxValue]);
    }
  };

  const convertCents = (amountInCents: number) => Math.trunc(amountInCents / 100);

  useEffect(() => {
    if (facet) {
      const { min, max, minSelected, maxSelected } = facet as RangeFacet;
      const minConverted = convertCents(min);
      const maxConverted = convertCents(max);

      setMinPrice(minConverted);
      setMaxPrice(maxConverted);

      // Setting default values
      if (minSelected && maxSelected) {
        const minSelectedConverted = convertCents(minSelected);
        const maxSelectedConverted = convertCents(maxSelected);
        updateValues([minSelectedConverted, maxSelectedConverted]);
      } else updateValues([minConverted, maxConverted]);
    }

    setInputWidth(widthRef.current.clientWidth);
  }, [router, facet]);

  useEffect(() => {
    onChange([values[0] * 100, values[1] * 100]);
  }, [values]);

  return (
    <div className="grid w-full gap-5">
      <div className="flex justify-between" ref={widthRef}>
        <>
          <PriceInput value={values[0]} onChange={(value) => updateValues([value, values[1]])} />
          {formatMessage({ id: 'filter.to', defaultMessage: 'bis' })}
          <PriceInput value={values[1]} onChange={(value) => updateValues([values[0], value])} />
        </>
      </div>
      {minPrice && (
        <TwoThumbInputRange
          thumbStyle={{
            width: '20px',
            height: '20px',
            border: '2px solid #0F4A48',
            borderRadius: '50%',
          }}
          inputStyle={{ width: inputWidth }}
          trackColor="#0F4A48"
          railColor="#0F4A48"
          thumbColor="#FFDDD3"
          showLabels={false}
          onChange={updateValues}
          values={values}
          min={minPrice}
          max={maxPrice}
        />
      )}
    </div>
  );
};

export default RangeFilter;
