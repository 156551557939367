import { useCart } from '../../../../../frontastic';
import { useCheckout } from '../../provider';
import { OrderSummaryTotals } from '../../types';
import { CartSummaryHandler } from '../../utils/CartSummaryHandler';
import DesktopOrderSummary from './desktop';
import MobileOrderSummary from './mobile';

type Props = {
  displayDesktop?: boolean;
  displayMobile?: boolean;
  deliveryTime?: string;
};

const OrderSummary = ({ displayDesktop = false, displayMobile = false, deliveryTime }: Props) => {
  const { data: cart } = useCart();
  const { shippingMethods, selectedShippingMethods, deliveryCountry } = useCheckout();

  const orderTotals: OrderSummaryTotals = {
    subTotal: CartSummaryHandler.getSubTotal(cart),
    discountTotal: CartSummaryHandler.getDiscountTotal(cart),
    shippingTotal: CartSummaryHandler.getShippingTotal(cart, deliveryCountry, shippingMethods, selectedShippingMethods),
  };

  // todo - use proper frontastic configuration
  const maxVoucherLimit = 1;

  return (
    <>
      {displayDesktop && (
        <DesktopOrderSummary orderTotals={orderTotals} maxVoucherLimit={maxVoucherLimit} deliveryTime={deliveryTime} />
      )}
      {displayMobile && (
        <MobileOrderSummary orderTotals={orderTotals} maxVoucherLimit={maxVoucherLimit} deliveryTime={deliveryTime} />
      )}
    </>
  );
};

export default OrderSummary;
