/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import { useFormat } from '../../../../../helpers/hooks/useFormat';
import { Variant } from '@Types/product/Variant';
import { getTranslationMap } from '../attribute/translationMapping';

export interface Props {
  readonly variant: Variant;
  readonly attributes: string[];
  readonly containerClassNames?: string;
}

type AttributeDetails = {
  attributeId: string;
  label: string;
  value: any;
};

export const MainAttributes = ({ variant, attributes, containerClassNames }: Props) => {
  const { formatMessage } = useFormat({ name: 'product' });
  const columns: AttributeDetails[][] = [
    getAttributeDetails(attributes.slice(0, Math.ceil(attributes.length / 2))),
    getAttributeDetails(attributes.slice(Math.ceil(attributes.length / 2))),
  ];

  function getAttributeDetails(attributeIds: string[]): AttributeDetails[] {
    return attributeIds.map((attributeId: string) => {
      let value = variant.attributes?.[attributeId];
      if (!Array.isArray(value) || value.length === 0) {
        value = null;
      }

      const translationMap = getTranslationMap(attributeId, value);

      return {
        attributeId,
        label: formatMessage({ id: translationMap.translationId, defaultMessage: attributeId }),
        value: translationMap.displayValue && value !== null ? translationMap.displayValue(value) : value,
      };
    });
  }

  return (
    <>
      <div className={containerClassNames ?? 'mt-4 flex flex-wrap'}>
        {columns.map((attributes: AttributeDetails[], index: number) => {
          return (
            <div key={`column-${index}`} className={`text-sm lg:text-${index % 2 === 0 ? 'left lg:flex-1' : 'right'}`}>
              {attributes.map((attribute: AttributeDetails, attributeId: number) => (
                <>
                  {attribute.value !== null && (
                    <div key={`a-${index}-${attributeId}`} className="mb-1">
                      <span>{attribute.label}</span>: <span className="font-semibold">{attribute.value}</span>
                    </div>
                  )}
                </>
              ))}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default MainAttributes;
