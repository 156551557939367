import React from 'react';
import { Disclosure } from '@headlessui/react';
import { MinusIcon, PlusIcon } from '@heroicons/react/outline';
import { Variant } from '@Types/product/Variant';
import { useFormat } from '../../../../../helpers/hooks/useFormat';
import { Product } from '@Types/product/Product';

export interface Props {
  variant: Variant;
  product: Product;
}

export default function VariantDescription({ variant, product }: Props) {
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });

  return (
    <>
      {product?.description && (
        <Disclosure key={variant.id}>
          {({ open }) => (
            <>
              <h3 className="bg-white">
                <Disclosure.Button className="group relative mb-1 flex h-[50px] w-full items-center justify-between py-2 px-3 text-left">
                  <span className="text-sm font-semibold text-gray-900">
                    {formatProductMessage({
                      id: 'variants.description.title',
                      defaultMessage: 'Description',
                    })}
                  </span>
                  <span className="ml-6 flex items-center">
                    {open ? (
                      <MinusIcon
                        className="block h-6 w-6 text-slate-900 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    ) : (
                      <PlusIcon className="block h-6 w-6 text-slate-900 group-hover:text-gray-500" aria-hidden="true" />
                    )}
                  </span>
                </Disclosure.Button>
              </h3>
              <Disclosure.Panel className="mb-1 -mt-0.5 bg-white">
                <div className="prose prose-sm p-3" dangerouslySetInnerHTML={{ __html: product?.description }} />
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      )}
    </>
  );
}
