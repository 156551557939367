import React from 'react';
import Image from 'next/future/image';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { ZoomInIcon } from '@heroicons/react/outline';
import { AttributeGroup } from '@Types/product/AttributeGroup';
import { Category } from '@Types/product/Category';
import { Money } from '@Types/product/Money';
import { Variant } from '@Types/product/Variant';
import ReactImageMagnify from 'react-image-magnify';
import Breadcrumb from 'components/revelo-ui/breadcrumb';
import Slider, { Slide, SliderProps } from 'components/revelo-ui/slider';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from '../../../../helpers/hooks/useMediaQuery';
import imageUrlResize from '../../../../helpers/imageUrlResize';
import { desktop } from '../../../../helpers/utils/screensizes';
import { TaxHandler } from '../../../revelo-ui/utils/TaxHandler';
import { Tooltip } from '../../types/Tooltip';
import BenefitsList from '../benefits-list';
import CartForm from './cart-form';
import MainAttributes from './main-attributes';
import PriceInfo from './price-info';
import VariantAttributes from './variant-attributes';
import VariantDescription from './variant-description';
import VariantSelection from './variant-selection';
import { TooltipHandler } from '../../utils/TooltipHandler';

export interface Props {
  product: UIProduct;
  onAddToCart: (variant: Variant, quantity: number) => Promise<void>;
  onAddToWishlist: () => void;
  variant: Variant;
  onChangeVariantIdx: (idx: number) => void;
  quickBuyEnabled?: boolean;
  benefits?: object;
  mainAttributes?: string[];
  attributeGroups?: AttributeGroup[];
  mobile?: boolean;
  tooltips?: Tooltip[];
  hideImageDisclaimer?: boolean;
}

export type UIProduct = {
  productId: string;
  name: string;
  variants: Variant[];
  price?: Money;
  images?: UIImage[];
  colors?: UIColor[];
  sizes?: UISize[];
  description: string;
  details?: UIDetail[];
  isOnWishlist?: boolean;
  _url: string;
  categories: Category[];
};
interface UIImage {
  id?: string;
  src?: string;
  alt?: string;
}
export interface UIColor {
  name?: string;
  key?: string;
  bgColor?: string;
  selectedColor?: string;
}
export interface UISize {
  label: string;
  key: string;
}
export interface UIVariant {
  sku: string;
  id?: string;
}
interface UIDetail {
  name: string;
  items: string[];
}

export default function ProductDetail({
  product,
  onAddToCart,
  onAddToWishlist,
  variant,
  onChangeVariantIdx,
  quickBuyEnabled,
  hideImageDisclaimer,
  benefits,
  attributeGroups,
  mobile,
  tooltips = [],
  mainAttributes = ['frame_height_manufacturer', 'frame_body_height_recommended_in_cm', 'mileage_in_km'],
}: Props) {
  //next/router
  const router = useRouter();
  const [isDesktopSize] = useMediaQuery(desktop);

  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const tooltipHandler = new TooltipHandler(tooltips);

  const HintComponent = () => (
    <div className={`zoom-hint absolute bottom-0 mb-2 flex w-full justify-center`}>
      <div className="flex items-center rounded-md bg-neutral-400 py-1.5 px-2.5 opacity-90">
        <ZoomInIcon width={25} className="text-white" />
        <span className="pt-0.5 pl-1.5 text-sm text-white">
          {formatProductMessage({ id: isDesktopSize ? 'image.hoverToZoom' : 'image.tapToZoom' })}
        </span>
      </div>
    </div>
  );

  const getImage = (image, isActive = false, isThumb = false) => {
    if (isDesktopSize && isActive) {
      return (
        <ReactImageMagnify
          {...{
            smallImage: {
              alt: image.alt || 'Alt',
              isFluidWidth: true,
              src: imageUrlResize(image.src, isThumb ? 'small' : 'large'),
            },
            largeImage: {
              src: image.src,
              width: 3472,
              height: 2320,
            },
            enlargedImagePortalId: 'magnifyContainer',
            enlargedImageContainerDimensions: {
              width: '74%',
              height: '100%',
            },
            enlargedImageContainerClassName: 'shadow-xl',
            enlargedImageStyle: {
              maxWidth: 'initial',
            },
            isHintEnabled: true,
            shouldHideHintAfterFirstActivation: false,
            hintComponent: HintComponent,
          }}
        />
      );
    }

    return (
      <>
        <Image
          loader={({ src }) => src}
          fill
          style={{ objectFit: 'contain' }}
          src={imageUrlResize(image.src, isThumb ? 'small' : 'original')}
          alt={image.alt}
          className="w-full object-center"
        />
        {isActive && <HintComponent />}
      </>
    );
  };

  const headline =
    Object.keys(variant.attributes).length > 0
      ? '<span class="font-semibold">' +
        variant?.attributes?.brand +
        ' ' +
        variant?.attributes?.model_name +
        '</span> - ' +
        variant?.attributes?.model_year
      : product.name;

  const sliderFixedMood: SliderProps = {
    slidesPerView: 1,
    arrows: true,
    dots: false,
    withThumbs: true,
    fitToSlides: true,
    zoom: {
      maxRatio: 5,
    },
    sliderVersion: 'v2',
  };

  const sliderConfiguration: SliderProps = sliderFixedMood;

  const availableVariants =
    variant?.availableQuantity === 0
      ? [variant]
      : product.variants?.filter((variant) => variant.availableQuantity > 0) || [];

  const onSelectVariant = (sku: string) => {
    const index = product?.variants?.findIndex((variant) => variant?.sku === sku);
    if (index !== -1) {
      onChangeVariantIdx(index);
    }
  };

  const onQuickBuy = async (variant: Variant) => {
    await onAddToCart(variant, 1);
    router.push('/checkout');
  };

  sliderConfiguration.thumbChildren = product.images.map((image) => getImage(image, false, true));

  const [category] = product.categories;

  return (
    <div>
      <Head>
        <title>
          {formatMessage({
            id: 'meta.pdp.title',
            values: {
              brand: variant?.attributes?.brand,
              modelName: variant?.attributes?.model_name,
              modelYear: variant?.attributes?.model_year,
            },
          })}
        </title>
        <meta
          name="description"
          content={formatMessage({
            id: 'meta.pdp.description',
            values: {
              brand: variant?.attributes?.brand,
              modelName: variant?.attributes?.model_name,
              category: category?.breadcrumbs[category.breadcrumbs.length - 1].name,
            },
          })}
        />
      </Head>
      <div className="mt-4 bg-white px-1 sm:px-3 lg:px-6">
        {category && <Breadcrumb breadcrumbs={category.breadcrumbs} isPDP />}
      </div>
      <div className="mx-auto max-w-2xl lg:max-w-7xl lg:px-6">
        <div className="lg:grid lg:grid-cols-5 lg:items-start lg:gap-x-8">
          <div className="md:py-4 lg:sticky lg:top-0 lg:z-10 lg:col-span-3">
            {!mobile && <div id="magnifyContainer" className="absolute left-[100%]" />}
            <div className="lg:pl-2">
              <Slider {...sliderConfiguration}>
                {product?.images?.map((image) => (
                  <Slide zoom={!isDesktopSize} key={image.id}>
                    {({ isActive }) => getImage(image, isActive)}
                  </Slide>
                ))}
              </Slider>
              {!hideImageDisclaimer && (
                <div className="prose mt-2 text-xs md:px-[20px]">
                  {formatProductMessage({
                    id: 'image.disclaimer',
                    defaultMessage: 'Die Bilder zeigen den tatsächlichen Zustand des Rads.',
                  })}
                </div>
              )}
            </div>
          </div>

          {/* Product info */}
          <div className="relative mt-5 md:mt-0 md:py-4 lg:col-span-2 lg:pr-2">
            {/* !mobile && <div id="magnifyContainer" className="absolute" />*/}
            <div className="flex flex-wrap justify-between">
              <h1
                className="text-xl leading-6 -tracking-[.01em] text-gray-900"
                dangerouslySetInnerHTML={{ __html: headline }}
              ></h1>
              <div className="basis-full text-xs font-light">Nr. {variant?.attributes['SKU'] ?? variant?.sku}</div>
            </div>

            <section aria-labelledby="main-attributes">
              {variant && <MainAttributes variant={variant} attributes={mainAttributes} />}
            </section>

            <div className="-mx-4 mt-4 rounded-md bg-neutral-100 p-4 -tracking-[.01em] md:mx-0">
              <h2 className="sr-only">
                {formatProductMessage({ id: 'product?.info', defaultMessage: 'Product information' })}
              </h2>

              <PriceInfo
                price={variant?.price ?? product.price}
                recommendedPrice={variant?.recommendedPrice}
                discountedPrice={variant?.discountedPrice}
                useDifferentialTaxation={TaxHandler.usesDifferentialTaxation(variant)}
                tooltips={tooltipHandler}
              />
            </div>

            <section aria-labelledby="benefits">
              <BenefitsList benefits={benefits} classNames="-mx-4 bg-transparent px-4 pt-4 pb-2 md:mx-0 md:px-0" />
            </section>

            {variant && (
              <section aria-labelledby="cart-form" className="block">
                <CartForm
                  formClassNames="mb-4"
                  variant={variant}
                  onAddToCart={onAddToCart}
                  onQuickBuy={onQuickBuy}
                  quickBuyEnabled={quickBuyEnabled}
                />
              </section>
            )}

            {availableVariants.length > 1 && (
              <section aria-labelledby="variants-heading" className="my-4">
                <VariantSelection
                  variants={availableVariants}
                  selectedVariant={variant}
                  onSelectVariant={onSelectVariant}
                />
              </section>
            )}

            {variant && (
              <section aria-labelledby="details-heading" className="-mx-4 bg-primary-900 p-4 md:mx-0">
                <h2 id="details-heading" className="mb-4 text-2xl font-semibold -tracking-[.01em] text-white">
                  {formatProductMessage({ id: 'details.additional', defaultMessage: 'Additional details' })}
                </h2>

                <VariantAttributes variant={variant} groups={attributeGroups} />
                <VariantDescription variant={variant} product={product} />
              </section>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
