import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Image from 'next/future/image';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { RadioGroup } from '@headlessui/react';
import { ZoomInIcon } from '@heroicons/react/outline';
import { LineItem } from '@Types/cart/LineItem';
import { AttributeGroup } from '@Types/product/AttributeGroup';
import { Category } from '@Types/product/Category';
import { Money } from '@Types/product/Money';
import { Variant } from '@Types/product/Variant';
import ReactImageMagnify from 'react-image-magnify';
import ListCheckIcon from 'components/icons/list-check';
import RefurbishedIcon from 'components/icons/refurbished';
import Breadcrumb from 'components/revelo-ui/breadcrumb';
import Slider, { Slide, SliderProps } from 'components/revelo-ui/slider';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from '../../../../frontastic';
import useMediaQuery from '../../../../helpers/hooks/useMediaQuery';
import imageUrlResize from '../../../../helpers/imageUrlResize';
import { desktop } from '../../../../helpers/utils/screensizes';
import { TaxHandler } from '../../../revelo-ui/utils/TaxHandler';
import Price from '../../price';
import VariantAttributes from './variant-attributes';
import VariantDescription from './variant-description';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export interface Props {
  product: UIProduct;
  onAddToCart: (variant: Variant, quantity: number) => Promise<void>;
  onAddToWishlist: () => void;
  variant: Variant;
  onChangeVariantIdx: (idx: number) => void;
  quickBuyEnabled?: boolean;
  benefits?: object;
  attributeGroups?: AttributeGroup[];
  mobile?: boolean;
}

export type UIProduct = {
  productId: string;
  name: string;
  variants: Variant[];
  price?: Money;
  images?: UIImage[];
  colors?: UIColor[];
  sizes?: UISize[];
  description: string;
  details?: UIDetail[];
  isOnWishlist?: boolean;
  _url: string;
  categories: Category[];
};
interface UIImage {
  id?: string;
  src?: string;
  alt?: string;
}
export interface UIColor {
  name?: string;
  key?: string;
  bgColor?: string;
  selectedColor?: string;
}
export interface UISize {
  label: string;
  key: string;
}
export interface UIVariant {
  sku: string;
  id?: string;
}
interface UIDetail {
  name: string;
  items: string[];
}

export default function ProductDetail({
  product,
  onAddToCart,
  onAddToWishlist,
  variant,
  onChangeVariantIdx,
  quickBuyEnabled,
  benefits,
  attributeGroups,
  mobile,
}: Props) {
  //next/router
  const router = useRouter();
  const [isDesktopSize] = useMediaQuery(desktop);

  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });

  const HintComponent = () => (
    <div className={`zoom-hint absolute bottom-${isDesktopSize ? '6' : '0'} flex w-full justify-center`}>
      <div className="flex items-center rounded-md bg-neutral-400 py-1.5 px-2.5 opacity-90">
        <ZoomInIcon width={25} className="text-white" />
        <span className="pt-0.5 pl-1.5 text-sm text-white">
          {formatProductMessage({ id: isDesktopSize ? 'image.hoverToZoom' : 'image.tapToZoom' })}
        </span>
      </div>
    </div>
  );

  const getImage = (image, isActive = false, isThumb = false) => {
    if (isDesktopSize && isActive) {
      return (
        <ReactImageMagnify
          {...{
            smallImage: {
              alt: image.alt || 'Alt',
              isFluidWidth: true,
              src: imageUrlResize(image.src, isThumb ? 'small' : 'large'),
            },
            largeImage: {
              src: image.src,
              width: 3472,
              height: 2320,
            },
            enlargedImagePortalId: 'magnifyContainer',
            enlargedImageContainerDimensions: {
              width: '100%',
              height: '100%',
            },
            enlargedImageContainerClassName: 'shadow-xl',
            enlargedImageStyle: {
              maxWidth: 'initial',
            },
            isHintEnabled: true,
            shouldHideHintAfterFirstActivation: false,
            hintComponent: HintComponent,
          }}
        />
      );
    }

    return (
      <>
        <Image
          loader={({ src }) => src}
          fill
          style={{ objectFit: 'contain' }}
          src={imageUrlResize(image.src, isThumb ? 'small' : 'original')}
          alt={image.alt}
          className="w-full object-center sm:rounded-lg"
        />
        {isActive && <HintComponent />}
      </>
    );
  };

  //Variant attributes
  const selectedColor = useMemo<UIColor | undefined>(
    () => product?.colors?.find((c) => c.key === variant?.attributes?.color?.key) ?? product?.colors?.[0],
    [product, variant],
  );

  const selectedSize = useMemo<UISize>(
    () => product?.sizes?.find((s) => s?.key === variant?.attributes?.commonSize?.key) ?? product?.sizes?.[0],
    [product, variant],
  );

  const selectedVariant = useMemo<UIVariant>(
    () => product?.variants?.find((s) => s?.sku === variant?.sku) ?? product?.variants?.[0],
    [product, variant],
  );

  //Component states
  const [loading, setLoading] = useState<boolean>(false);
  const [added, setAdded] = useState<boolean>(false);

  // Cart details
  const { data: cart } = useCart();

  const headline =
    Object.keys(variant.attributes).length > 0
      ? '<span class="font-semibold">' +
        variant?.attributes?.brand +
        ' ' +
        variant?.attributes?.model_name +
        '</span> - ' +
        variant?.attributes?.model_year
      : product.name;

  const bodyHeightMin = variant?.attributes?.frame_body_height_recommended_in_cm?.reduce((a, b) => {
    return Math.min(a, b);
  });
  const bodyHeightMax = variant?.attributes?.frame_body_height_recommended_in_cm?.reduce((a, b) => {
    return Math.max(a, b);
  });

  const discount = (
    ((variant?.recommendedPrice?.centAmount - variant?.price?.centAmount) / variant?.recommendedPrice?.centAmount) *
    100
  )
    .toFixed(2)
    .replace('.', ',');

  const sliderFixedMood: SliderProps = {
    slidesPerView: 1,
    arrows: true,
    dots: false,
    withThumbs: true,
    fitToSlides: true,
    zoom: {
      maxRatio: 5,
    },
  };

  const sliderConfiguration: SliderProps = sliderFixedMood;

  const availableVariants =
    variant?.availableQuantity === 0
      ? [variant]
      : product.variants?.filter((variant) => variant.availableQuantity > 0) || [];

  const selectSize = (size: UISize) => {
    onChangeVariantIdx(
      product?.variants?.findIndex(
        (variant) =>
          variant?.attributes?.color?.key === selectedColor.key && variant?.attributes?.commonSize?.key === size.key,
      ) ?? 0,
    );
  };

  const selectColor = (color: UIColor) => {
    onChangeVariantIdx(
      product?.variants?.findIndex(
        (variant) =>
          variant?.attributes?.color?.key === color.key && variant?.attributes?.commonSize?.key === selectedSize.key,
      ) ?? 0,
    );
  };

  const selectVariant = (selection: UIVariant) => {
    const index = product?.variants?.findIndex((variant) => variant?.sku === selection.sku);
    if (index !== -1) {
      onChangeVariantIdx(index);
    }
  };

  const variantExistsFor = useCallback(
    (color: UIColor, size: UISize) => {
      return !!product?.variants?.find(
        (v) => v.attributes?.color?.key === color?.key && v.attributes?.frame_height_manufacturer?.key === size?.key,
      );
    },
    [product],
  );

  const handleAddToCart = async () => {
    if (loading || !variant.isOnStock) return;
    setLoading(true);
    await onAddToCart(variant, 1);
    setLoading(false);
    setAdded(true);
  };

  const handleQuickBuy = async () => {
    await onAddToCart(variant, 1);
    router.push('/checkout');
  };

  const isInCart = (variant: Variant): boolean => {
    const lineItem = cart?.lineItems?.find((lineItem: LineItem) => lineItem.variant?.sku === variant.sku);
    return !!lineItem;
  };

  useEffect(() => {
    if (added) {
      setTimeout(() => {
        setAdded(false);
      }, 1000);
    }
  }, [added]);

  sliderConfiguration.thumbChildren = product.images.map((image) => getImage(image, false, true));

  const [category] = product.categories;

  return (
    <div>
      <Head>
        <title>
          {formatMessage({
            id: 'meta.pdp.title',
            values: {
              brand: variant?.attributes?.brand,
              modelName: variant?.attributes?.model_name,
              modelYear: variant?.attributes?.model_year,
            },
          })}
        </title>
        <meta
          name="description"
          content={formatMessage({
            id: 'meta.pdp.description',
            values: {
              brand: variant?.attributes?.brand,
              modelName: variant?.attributes?.model_name,
              category: category?.breadcrumbs[category.breadcrumbs.length - 1].name,
            },
          })}
        />
      </Head>
      <div className="mt-4 px-1 sm:px-3 lg:px-6">
        {category && <Breadcrumb breadcrumbs={category.breadcrumbs} isPDP />}
      </div>
      <div className=" mx-auto max-w-2xl md:py-4 lg:max-w-7xl lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">
          <Slider {...sliderConfiguration}>
            {product?.images?.map((image) => (
              <Slide zoom={!isDesktopSize} key={image.id}>
                {({ isActive }) => getImage(image, isActive)}
              </Slide>
            ))}
          </Slider>

          {/* Product info */}
          <div className="relative mt-10 sm:mt-16 lg:mt-0">
            {!mobile && <div id="magnifyContainer" className="absolute" />}
            <div className="flex flex-wrap justify-between">
              <h1
                className="text-lg leading-6 -tracking-[.01em] text-gray-900"
                dangerouslySetInnerHTML={{ __html: headline }}
              ></h1>
              <div className="basis-full text-xs font-light">Nr. {variant?.sku}</div>
            </div>
            <div className="mt-2 flex flex-wrap">
              {variant?.attributes?.frame_height_manufacturer ? (
                <div className="basis-6/12 text-xs">
                  {formatProductMessage({ id: 'frameSize', defaultMessage: 'Rahmengröße' })}:{' '}
                  {variant?.attributes?.frame_height_manufacturer}
                </div>
              ) : null}
              {variant?.attributes?.mileage_in_km ? (
                <div className="basis-6/12 text-xs">
                  {formatProductMessage({ id: 'mileage', defaultMessage: 'Laufleistung' })}:{' '}
                  {variant?.attributes?.mileage_in_km} km
                </div>
              ) : null}
              {bodyHeightMin && bodyHeightMax ? (
                <div className="flex-1 basis-6/12 text-xs">
                  {formatProductMessage({ id: 'appropriateBodySize', defaultMessage: 'Passende Körpergröße' })}:{' '}
                  {bodyHeightMin}-{bodyHeightMax} cm
                </div>
              ) : null}
            </div>
            <div className="-mx-4 mt-4 flex flex-wrap bg-primary-100 p-4 -tracking-[.01em] md:mx-0">
              <div className="basis-6/12 pr-2">
                <h2 className="sr-only">
                  {formatProductMessage({ id: 'product?.info', defaultMessage: 'Product information' })}
                </h2>
                <div className="flex">
                  <Price
                    price={product?.price}
                    className={`${
                      variant.discountedPrice ? 'line-through' : ''
                    } text-2xl font-semibold text-gray-900 md:text-3xl`}
                  />
                  {variant.discountedPrice && (
                    <Price price={variant?.discountedPrice} className="pl-6 text-2xl text-accent-400 md:text-3xl" />
                  )}
                </div>
                <div
                  className="mb-2 text-xs"
                  dangerouslySetInnerHTML={{
                    __html: TaxHandler.usesDifferentialTaxation(variant)
                      ? formatProductMessage({
                          id: 'vatDiffInfo',
                          defaultMessage:
                            'Artikel unterliegt der Differenzbesteuerung gem. § 25a UStG, keine MWSt. ausweisbar ggf. zzgl. Versandkosten',
                        })
                      : formatProductMessage({ id: 'vatInfo', defaultMessage: 'inkl. MwSt. ggf. zzgl. Versandkosten' }),
                  }}
                />
                {variant.recommendedPrice && (
                  <div className="mb-2 flex text-xs font-light">
                    {formatProductMessage({ id: 'uvp', defaultMessage: 'ehem. Neupreis' })}: &nbsp;
                    <Price price={variant?.recommendedPrice} className="font-normal" />
                  </div>
                )}
                {variant.recommendedPrice && (
                  <div className="text-xs">
                    <strong>{discount}%</strong> {formatProductMessage({ id: 'cheaper', defaultMessage: 'günstiger' })}
                  </div>
                )}
              </div>

              <div className="basis-6/12 pl-2 text-sm">
                <div className="mb-4 flex items-center gap-2 text-primary-400">
                  <RefurbishedIcon className="shrink-0" aria-hidden="true" />
                  <span>{formatProductMessage({ id: 'refurbished', defaultMessage: 'Refurbished' })}</span>
                </div>
                {Object.values(benefits).map((benefit, i) => (
                  <div className="mb-2 flex gap-2" key={i}>
                    <ListCheckIcon className="shrink-0" aria-hidden="true" />
                    <span dangerouslySetInnerHTML={{ __html: benefit }}></span>
                  </div>
                ))}
              </div>

              <form className="mt-4 basis-full">
                {product?.colors?.length !== undefined && product.colors.length > 0 && (
                  <div>
                    <h3 className="text-sm text-gray-900">
                      {formatProductMessage({ id: 'color.select', defaultMessage: 'Select color' })}
                    </h3>

                    <RadioGroup value={selectedColor} onChange={(e) => selectColor(e)} className="mt-4">
                      <RadioGroup.Label className="sr-only">Choose a color</RadioGroup.Label>
                      <div className="flex items-center space-x-3">
                        {product.colors?.map((color) => (
                          <RadioGroup.Option
                            key={color.name}
                            value={color}
                            className={({ active, checked }) =>
                              classNames(
                                (active && checked) || selectedColor?.key === color.key
                                  ? `ring-2 ring-offset-2 ${color.selectedColor}`
                                  : '',
                                'relative flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none',
                              )
                            }
                          >
                            <RadioGroup.Label>
                              <p className="sr-only">{color.name}</p>
                            </RadioGroup.Label>
                            <span
                              aria-hidden="true"
                              className={classNames(
                                color.bgColor,
                                'border-black h-6 w-6 rounded-full border border-opacity-10',
                              )}
                            />
                          </RadioGroup.Option>
                        ))}
                      </div>
                    </RadioGroup>
                  </div>
                )}

                {product.sizes?.length !== undefined && product.sizes.length > 1 && (
                  <div className="mt-8">
                    <div className="flex items-center justify-between">
                      <h2 className="text-sm text-gray-900">
                        {formatProductMessage({ id: 'size.select', defaultMessage: 'Select size' })}
                      </h2>
                    </div>

                    <RadioGroup value={selectedSize} onChange={(e) => selectSize(e)} className="mt-4 hidden lg:block">
                      <RadioGroup.Label className="sr-only">Choose a size</RadioGroup.Label>
                      <div className="grid grid-cols-3 gap-3 sm:grid-cols-6">
                        {product.sizes
                          .filter((size) => variantExistsFor(selectedColor, size))
                          .map((size) => (
                            <RadioGroup.Option
                              key={size.key}
                              value={size}
                              className={({ active, checked }) =>
                                classNames(
                                  active || selectedSize?.key === size.key || checked ? 'ring-1 ring-primary-400' : '',
                                  'cursor-pointer rounded-sm bg-neutral-200 py-2 px-4 text-center font-light text-gray-900 transition duration-150 ease-out hover:bg-neutral-300',
                                )
                              }
                            >
                              <RadioGroup.Label className="cursor-pointer">
                                <p>{size.label}</p>
                              </RadioGroup.Label>
                            </RadioGroup.Option>
                          ))}
                      </div>
                    </RadioGroup>

                    <select
                      onChange={(e) => selectSize(product.sizes.find((size) => size.key === e.target.value))}
                      className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm lg:hidden"
                      defaultValue="Canada"
                      value={selectedSize.key}
                    >
                      {product.sizes.map((size) => (
                        <option key={size.key} value={size.key}>
                          {size}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                <div className="align-stretch flex flex-col gap-4">
                  {quickBuyEnabled && (
                    <button
                      type="button"
                      onClick={handleQuickBuy}
                      className={classNames(
                        'flex w-full flex-1 items-center justify-center rounded-md border border-transparent bg-accent-400 py-3 px-8  text-base font-medium text-white transition duration-150 ease-out hover:bg-accent-500 focus:bg-accent-500 focus:outline-none focus:ring-2 focus:ring-accent-400 focus:ring-offset-2 focus:ring-offset-gray-50 disabled:bg-gray-400',
                      )}
                      disabled={!variant.isOnStock}
                    >
                      Buy Now
                    </button>
                  )}
                  <button
                    type="button"
                    onClick={handleAddToCart}
                    className={classNames(
                      'flex w-full flex-1 items-center justify-center rounded-md border py-3 px-8 text-base font-medium  transition duration-150 ease-out focus:outline-none focus:ring-2 focus:ring-accent-400 focus:ring-offset-2 focus:ring-offset-gray-50 disabled:bg-gray-400',
                      !quickBuyEnabled
                        ? 'border-transparent bg-accent-400 fill-white text-white hover:bg-accent-500 focus:bg-accent-500'
                        : 'border-accent-400 bg-white fill-accent-400 text-accent-400 hover:bg-accent-400 hover:fill-white hover:text-white',
                    )}
                    disabled={!variant.isOnStock || isInCart(variant)}
                  >
                    {!loading && !added && (
                      <>
                        {isInCart(variant)
                          ? formatProductMessage({ id: 'bag.contains', defaultMessage: 'Already in cart' })
                          : variant.isOnStock
                          ? formatProductMessage({ id: 'bag.add', defaultMessage: 'Add to bag' })
                          : formatProductMessage({ id: 'outOfStock', defaultMessage: 'Out of stock' })}
                      </>
                    )}

                    {loading && (
                      <svg className="h-6 w-6 animate-spin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
                        <path d="M8,8.5A3.5,3.5,0,1,1,4.5,5,3.5,3.5,0,0,1,8,8.5ZM4.5,14A3.5,3.5,0,1,0,8,17.5,3.5,3.5,0,0,0,4.5,14Zm16-2A3.5,3.5,0,1,0,17,8.5,3.5,3.5,0,0,0,20.5,12Zm0,2A3.5,3.5,0,1,0,24,17.5,3.5,3.5,0,0,0,20.5,14Zm-8,4A3.5,3.5,0,1,0,16,21.5,3.5,3.5,0,0,0,12.5,18Zm0-18A3.5,3.5,0,1,0,16,3.5,3.5,3.5,0,0,0,12.5,0Z" />
                      </svg>
                    )}
                    {!loading && added && (
                      <svg className="h-6 w-6" viewBox="0 0 80.588 61.158">
                        <path
                          d="M29.658,61.157c-1.238,0-2.427-0.491-3.305-1.369L1.37,34.808c-1.826-1.825-1.826-4.785,0-6.611
                     c1.825-1.826,4.786-1.827,6.611,0l21.485,21.481L72.426,1.561c1.719-1.924,4.674-2.094,6.601-0.374
                     c1.926,1.72,2.094,4.675,0.374,6.601L33.145,59.595c-0.856,0.959-2.07,1.523-3.355,1.56C29.746,61.156,29.702,61.157,29.658,61.157z
                     "
                        />
                      </svg>
                    )}
                  </button>
                </div>
              </form>
            </div>

            {availableVariants.length > 1 && (
              <section aria-labelledby="variants-heading" className="my-4">
                <h2 id="variants-heading" className="mb-4 text-2xl font-semibold -tracking-[.01em] text-font-default">
                  {formatProductMessage({ id: 'variants.title', defaultMessage: 'Variants title' })}
                </h2>

                <div>
                  <RadioGroup value={selectedVariant} onChange={(e) => selectVariant(e)}>
                    {availableVariants.map((variant) => (
                      <div key={variant.id} className="mb-1 flex items-center rounded bg-light-150 p-3">
                        <RadioGroup.Option
                          key={variant.sku}
                          value={variant}
                          className={({ active, checked }) =>
                            classNames(
                              active || selectedVariant?.sku === variant.sku || checked
                                ? 'border-[6px] border-primary-400 bg-transparent'
                                : '',
                              'h-5 w-5 flex-none rounded-full border-4 border-gray-300 text-primary-400 hover:cursor-pointer hover:border-primary-400 focus:ring-primary-400',
                            )
                          }
                        />
                        <RadioGroup.Label className="flex flex-auto shrink-0 justify-around">
                          <span>{variant.attributes?.frame_height_manufacturer}</span>
                          {variant.attributes?.mileage_in_km && <span>{variant.attributes?.mileage_in_km} km</span>}
                          <span>
                            <Price price={variant?.price} className="" />
                          </span>
                        </RadioGroup.Label>
                      </div>
                    ))}
                  </RadioGroup>
                </div>
              </section>
            )}

            {variant && (
              <section aria-labelledby="details-heading" className="-mx-4 bg-primary-900 p-4 md:mx-0">
                <h2 id="details-heading" className="mb-4 text-2xl font-semibold -tracking-[.01em] text-white">
                  {formatProductMessage({ id: 'details.additional', defaultMessage: 'Additional details' })}
                </h2>

                <VariantAttributes variant={variant} groups={attributeGroups} />
                <VariantDescription variant={variant} product={product} />
              </section>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
