import ButtonElement from '../../../forms/form-elements/ButtonElement';
import InputElement from '../../../forms/form-elements/InputElement';
import LabelElement from '../../../forms/form-elements/LabelElement';
import DiscountInputProps from '../interfaces/DiscountInputProps';
import Spinner from '../../../../commercetools-ui/spinner';

const DiscountInput = (props: DiscountInputProps) => {
  const onCodeChange = (field: string, value: string) => {
    props.handleCodeChange(value?.trim());
  };

  return (
    <div className={props.classNames?.wrapper || `col-span-full`}>
      <LabelElement
        fieldId={props.name}
        label={props.label}
        formId={props.formId}
        classNames={props.classNames?.label || `text-xs text-neutral-600 font-semibold`}
      />

      <div className="my-1 flex justify-between">
        {props.isProcessing && !!props.showSpinner ? (
          <div className={`${props.classNames?.inputContainer || 'w-full pr-5'} flex items-center justify-center`}>
            <Spinner color="#0F4A48" />
          </div>
        ) : (
          <InputElement
            type="text"
            onChange={onCodeChange}
            isDisabled={props.isProcessing}
            value={props.discountCode}
            name={props.name}
            containerClassNames={props.classNames?.inputContainer || `pr-1.5 w-full`}
            classNames={
              props.classNames?.inputField ||
              `block w-full rounded-lg border-2 p-2 shadow border-neutral-400 focus:border-primary-400 focus:ring-primary-400 sm:text-sm`
            }
          />
        )}
        <ButtonElement
          onClick={props.handleApply}
          isDisabled={props.isProcessing || !props.discountCode}
          buttonText={props.buttonLabel ?? 'Apply'}
          containerClassNames={props.classNames?.buttonContainer || `mt-0`}
          classNames={
            props.classNames?.applyButton ||
            `transition duration-150 ease-out focus:outline-none focus:ring-2 focus:ring-offset-2 w-full rounded-md border-2 border-transparent py-2 px-5 text-md font-semibold bg-primary-400 text-white hover:bg-primary-500 focus:bg-primary-500 focus:ring-primary-400 disabled:bg-primary-300 lg:py-1.5`
          }
        />
      </div>

      {props.statusMessage?.length > 0 && (
        <div className={props.classNames?.status || 'mt-1 block text-xs font-medium text-error-100'}>
          {props.statusMessage}
        </div>
      )}
    </div>
  );
};

export default DiscountInput;
