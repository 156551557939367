import React from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from '../../../../../frontastic';
import Price from '../../../../commercetools-ui/price';
import TruckIcon from '../../../../icons/truck';
import DiscountField from '../../../discount/discount-field';
import { CartSummaryHandler } from '../../utils/CartSummaryHandler';
import OrderSummaryProps from './interfaces/OrderSummaryProps';
import SummaryLineItem from './line-item';

const DesktopOrderSummary = ({ orderTotals, maxVoucherLimit, deliveryTime }: OrderSummaryProps) => {
  //i18n messages
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });

  const { data: cart } = useCart();

  return (
    <section
      aria-labelledby="summary-heading"
      className="hidden w-full max-w-md flex-col bg-neutral-200 dark:bg-primary-400 lg:flex"
    >
      <h2 id="summary-heading" className="sr-only">
        {formatCartMessage({ id: 'order.summary', defaultMessage: 'Order summary' })}
      </h2>

      <ul role="list" className="flex-auto overflow-y-auto px-6 pt-6">
        {cart.lineItems.map((lineItem, i) => (
          <li key={i} className="flex space-x-6 py-1 first:pt-0">
            <SummaryLineItem lineItem={lineItem} />
          </li>
        ))}
      </ul>

      <div className="sticky bottom-0 flex-none bg-neutral-200">
        <DiscountField name="discountCodeDesktop" maxVoucherLimit={maxVoucherLimit} summaryType="pills" />

        <div className="bg-neutral-200 px-6 pb-6 dark:bg-primary-400">
          <dl className="border-t border-neutral-400 pt-5 text-sm font-normal text-neutral-900 dark:text-light-100">
            {orderTotals.subTotal && (
              <div className="flex justify-between">
                <dt>{formatCheckoutMessage({ id: 'subtotal', defaultMessage: 'Subtotal' })}</dt>
                <dd>
                  <Price price={orderTotals.subTotal} className="text-neutral-900 dark:text-light-100" />
                </dd>
              </div>
            )}
            {orderTotals.discountTotal && orderTotals.discountTotal.centAmount !== 0 && (
              <div className="mt-2.5 flex justify-between">
                <dt className="flex font-normal text-neutral-900">
                  {formatCartMessage({ id: 'discounts', defaultMessage: 'Discounts' })}
                </dt>
                <dd>
                  <Price price={orderTotals.discountTotal} className="font-bold text-neutral-900 dark:text-light-100" />
                </dd>
              </div>
            )}
            {orderTotals.shippingTotal && (
              <div className="mt-2.5 flex justify-between">
                <dt>
                  {formatCheckoutMessage({ id: 'shipping', defaultMessage: 'Shipping' })}
                  <span className="mt-1 flex items-center gap-2.5 text-xs font-light text-primary-300">
                    <TruckIcon className="h-5 w-5 shrink-0" aria-hidden="true" />
                    {deliveryTime}
                  </span>
                </dt>
                <dd>
                  <Price
                    price={orderTotals.shippingTotal}
                    className={`font-bold text-neutral-900 dark:text-light-100${
                      orderTotals.shippingTotal.centAmount > 0 && " after:content-['*']"
                    }`}
                  />
                </dd>
              </div>
            )}
            <div className="mt-4 flex items-center justify-between border-t border-neutral-400 pt-5 text-neutral-900 dark:text-light-100">
              <dt className="text-sm font-bold text-neutral-900 dark:text-light-100">
                {formatCheckoutMessage({ id: 'total', defaultMessage: 'Total' })}
                <span className="text-sm font-light">
                  &nbsp;{formatCheckoutMessage({ id: 'inclVat', defaultMessage: '(inkl. Mwst)' })}
                </span>
              </dt>
              <dd>
                <Price
                  price={orderTotals.orderTotal || CartSummaryHandler.calculateOrderTotal(cart, orderTotals)}
                  className="text-sm font-bold text-neutral-900 dark:text-light-100"
                />
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </section>
  );
};

export default DesktopOrderSummary;
