export type TranslationMap = {
  attributeId: string;
  translationId: string;
  defaultMessage?: string;
  displayValue?: (value: any) => string;
};

const translationMap: TranslationMap[] = [
  {
    attributeId: 'frame_body_height_recommended_in_cm',
    translationId: 'appropriateBodySize',
    defaultMessage: 'Passende Körpergröße',
    displayValue: (value) => `${value.reduce((a, b) => Math.min(a, b))} - ${value.reduce((a, b) => Math.max(a, b))} cm`,
  },
  {
    attributeId: 'mileage_in_km',
    translationId: 'mileage',
    defaultMessage: 'Laufleistung',
    displayValue: (value) => `${value} km`,
  },
  {
    attributeId: 'weight_in_kg',
    translationId: 'weight',
    defaultMessage: 'Gewicht',
    displayValue: (value) => `${value} kg`,
  },
  {
    attributeId: 'battery_capacity_in_wh',
    translationId: 'batteryCapacity',
    defaultMessage: 'Akkukapazität',
    displayValue: (value) => `${value} Wh`,
  },
  {
    attributeId: 'fork_travel_in_mm',
    translationId: 'forkTravel',
    defaultMessage: 'Federweg Gabel',
    displayValue: (value) => `${value} mm`,
  },
  {
    attributeId: 'damper_travel_in_mm',
    translationId: 'damperTravel',
    defaultMessage: 'Federweg Dämpfer',
    displayValue: (value) => `${value} mm`,
  },
];

export const getTranslationMap = (attributeId: string, value?: any): TranslationMap => {
  const map = translationMap.find((map) => map.attributeId === attributeId);

  return (
    map ?? {
      attributeId: attributeId,
      translationId: attributeId.replace(/_./g, (data) => data[1].toUpperCase()),
      defaultMessage: attributeId.replace(/_/g, ' '),
    }
  );
};
