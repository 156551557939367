import React, { useEffect, useRef } from 'react';
import NextLink from 'next/link';
import { BannerInPLP } from '@Types/content/Banner';
import { Product } from '@Types/product/Product';
import { Variant } from '@Types/product/Variant';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import Image from 'frontastic/lib/image';
import imageUrlResize from '../../../../helpers/imageUrlResize';
import Typography from '../../../commercetools-ui/typography';
import Banner from '../../../revelo-ui/content/banner';
import DataValidator from '../../../../helpers/utils/dataValidator';

interface Props {
  products: Product[];
  totalProducts: number;
  banners: BannerInPLP[];
  isFirstPage: boolean;
}

// START: Banner's functions
let validBanners: BannerInPLP[] = [];

const getShowDeviceClasses = (showDevices: 'showAll' | 'showDesktop' | 'showMobile') => {
  switch (showDevices) {
    case 'showDesktop':
      return '!hidden md:!flex';
    case 'showMobile':
      return 'md:!hidden';
    default:
      return '';
  }
};

const getValidBanners = (banners: BannerInPLP[], totalProducts: number, isFirstPage: boolean) => {
  if (!banners || banners.length < 1 || !isFirstPage) return [];

  return banners
    .filter(
      (banner) =>
        banner.maxPosition > 0 && banner.minProducts <= banner.maxPosition && banner.minProducts <= totalProducts,
    )
    .sort((a, b) => a.maxPosition - b.maxPosition)
    .map((banner) => ({
      actualIndex: banner.maxPosition,
      ...banner,
      showDevicesClasses: getShowDeviceClasses(banner.showDevices),
    }));
};
// END: Banner's functions

const getVariantAttributeList = ({ attributes }: Variant) => {
  const attributeList = [];

  if (!Array.isArray(attributes)) {
    const { model_year, frame_height_manufacturer, mileage_in_km } = attributes;

    if (model_year?.[0]) attributeList.push(model_year?.[0]);
    if (frame_height_manufacturer?.[0]) attributeList.push(frame_height_manufacturer?.[0]);
    if (mileage_in_km?.[0]) attributeList.push(`${mileage_in_km?.[0]} km`);
  }

  return attributeList;
};

const renderVariantsList = (variants: Variant[], formatMessage) => {
  variants = variants.filter((variant) => variant.availableQuantity > 0);
  if (variants.length === 0) {
    return null;
  }

  let content;

  if (variants.length > 2) {
    content = (
      <>
        {variants[0].attributes?.model_year && <div className="text-xs">{variants[0].attributes?.model_year?.[0]}</div>}
        <div className="bottom-0 text-xs">
          {formatMessage({ id: 'variants.multiple', defaultMessage: 'different sizes & mileage available' })}
        </div>
      </>
    );
  } else {
    content = variants.map((variant, index) => {
      const attributeList = getVariantAttributeList(variant);

      if (attributeList.length === 0) return null;

      return (
        <div
          key={index}
          className="text-xs"
          dangerouslySetInnerHTML={{
            __html: attributeList.join('<span class="px-2">&centerdot;</span>'),
          }}
        />
      );
    });
  }

  return <div className="relative h-12">{content}</div>;
};

const getProductImageSrc = (product: Product, mustFitToUrl = true) => {
  const variant = product.variants.find((variant) => {
    if (!mustFitToUrl) {
      return variant.availableQuantity > 0;
    }

    return variant.availableQuantity > 0 && new RegExp(`/p/${variant.sku}`).test(product._url ?? '');
  });

  let imageUrl =
    !variant && mustFitToUrl
      ? getProductImageSrc(product, false)
      : !!variant?.images?.[0]
      ? variant.images[0]
      : product.variants[0].images?.[0] ?? '';

  imageUrl = imageUrlResize(imageUrl, 'medium');

  return imageUrl;
};

const getProductPrice = (product: Product, formatMessage) => {
  const availableVariants = product.variants.filter((variant) => variant.availableQuantity);
  const useFromPrice = availableVariants.length > 1;

  const priceVariant = useFromPrice
    ? availableVariants.sort((a, b) => a.price.centAmount - b.price.centAmount)[0]
    : availableVariants.length === 1
    ? availableVariants[0]
    : product.variants[0];

  const recommendedPriceFormatted = CurrencyHelpers.formatForCurrency(priceVariant.recommendedPrice);
  const priceFormatted = CurrencyHelpers.formatForCurrency(priceVariant.price);

  return (
    <div className="mt-3">
      <div className="text-sm font-semibold text-accent-400">
        <Typography>
          {useFromPrice
            ? formatMessage({
                id: 'price.from',
                defaultMessage: 'ab {price}',
                values: { price: priceFormatted },
              })
            : priceFormatted}
        </Typography>
      </div>
      <div className="text-sm text-neutral-400">
        <Typography>
          {formatMessage({
            id: 'price.uvp',
            defaultMessage: 'ehem. UVP {price}',
            values: { price: recommendedPriceFormatted },
          })}
        </Typography>
      </div>
    </div>
  );
};

const boxClasses = 'flex justify-center rounded border border-primary-100 transition-shadow';

const List: React.FC<Props> = ({ products, totalProducts, banners, isFirstPage }) => {
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });

  const updateValidBannersRef = useRef<BannerInPLP[]>([]);
  useEffect(() => {
    validBanners = getValidBanners(banners, totalProducts, isFirstPage);

    let previousPositionInLoop = null;
    let countShownBanners = 0;
    const updatedBanners = validBanners.map((banner, i) => {
      if (i == 0) {
        previousPositionInLoop = banner.maxPosition;
      } else {
        if (banner.maxPosition == validBanners[i - 1].maxPosition) {
          return {
            ...banner,
            actualIndex: previousPositionInLoop,
          };
        } else if (banner.maxPosition > validBanners[i - 1].maxPosition) {
          countShownBanners++;
          previousPositionInLoop = banner.maxPosition - countShownBanners;
          return {
            ...banner,
            actualIndex: previousPositionInLoop,
          };
        }
      }

      return banner;
    });

    updateValidBannersRef.current = updatedBanners;
  }, [products, totalProducts, banners, isFirstPage]);

  return (
    <div className="mx-auto max-w-2xl pt-8 lg:max-w-7xl">
      <h2 className="sr-only">{formatProductMessage({ id: 'products', defaultMessage: 'Products' })}</h2>
      <ul className="grid grid-cols-1 gap-5 align-bottom sm:grid-cols-2 md:grid-cols-3 md:gap-8 lg:grid-cols-3">
        {products?.map((product, productIndex) => {
          const renderBannerElements = updateValidBannersRef.current
            .map((banner, bannerIndex) => {
              if (banner.actualIndex === productIndex + 1) {
                const validBannerLink = DataValidator.isValidReference(banner.bannerLink)
                  ? banner.bannerLink
                  : undefined;

                return (
                  <li
                    key={`banner-${bannerIndex}`}
                    className={`PLP-banner ${banner.showDevicesClasses} ${boxClasses} ${
                      validBannerLink && 'hover:shadow-xl'
                    } overflow-hidden `}
                    data-position={banner.maxPosition}
                    data-actual-index={banner.actualIndex}
                  >
                    <Banner
                      backgroundImage={banner.backgroundImage}
                      backgroundImageFit="contain"
                      backgroundColor={banner.backgroundColor}
                      bannerText={banner.bannerText}
                      textColor={banner.textColor}
                      buttonLabel={banner.buttonLabel}
                      buttonStyle={banner.buttonStyle}
                      buttonLink={banner.buttonLink}
                      bannerLink={banner.bannerLink}
                      customClassName="min-h-[280px]"
                    />
                  </li>
                );
              }
              return null;
            })
            .filter((element) => element !== null);

          return (
            <React.Fragment key={`product-fragment-${product.productId}-${Math.random()}`}>
              {renderBannerElements}
              <li
                key={`product-${product.productId}`}
                className={`PLP-product ${boxClasses} self-end px-5 py-2.5 hover:shadow-xl`}
              >
                <NextLink href={product._url || ''}>
                  <a className="group max-w-full">
                    <div className="relative w-full">
                      <Image src={getProductImageSrc(product, true) || ''} alt={product.name} layout="fill" />
                    </div>
                    <div className="mt-4 text-sm">{product.variants[0].attributes?.brand?.[0]}</div>
                    <h3
                      className="w-full overflow-hidden truncate text-base font-semibold text-gray-700 dark:text-light-100"
                      title={product.variants[0].attributes?.model_name?.[0]}
                    >
                      {product.variants[0].attributes?.model_name?.[0]}
                    </h3>
                    {renderVariantsList(product.variants, formatProductMessage)}
                    {getProductPrice(product, formatProductMessage)}
                  </a>
                </NextLink>
              </li>
            </React.Fragment>
          );
        })}
      </ul>
    </div>
  );
};

export default List;
