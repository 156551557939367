import { Facet } from '@Types/result/Facet';

export class FilterUtils {
  private static readonly ATTRIBUTE_PREFIX = 'variants.attributes.';

  public static filterFacetsByType(facets: Facet[], facetType: string): Facet[] {
    return facets.filter(({ type }) => facetType === type);
  }

  public static sortFacetsByList(facets: Facet[], list: string[], removeUnlisted = true): Facet[] {
    if (list.length === 0) {
      return facets;
    }

    const sortedFacets: Facet[] = [];
    for (const facetId of list) {
      const facet = facets.find((facet) => FilterUtils.getFacetIdByKey(facet) === facetId);
      if (facet) {
        sortedFacets.push(facet);
      }
    }

    if (!removeUnlisted && sortedFacets.length < facets.length) {
      sortedFacets.push(...facets.filter((facet) => !list.includes(FilterUtils.getFacetIdByKey(facet))));
    }

    return sortedFacets;
  }

  private static getFacetIdByKey(facet: Facet): string {
    return facet.key.replace(FilterUtils.ATTRIBUTE_PREFIX, '').toLowerCase();
  }
}
