import { Address } from '@Types/account/Address';
import { useFormContext } from 'react-hook-form';
import { useFormat } from 'helpers/hooks/useFormat';
import FormFieldInput from '../../../forms/form-field-input';
import FormFieldSelect from '../../../forms/form-field-select';
import { isValidPostalCode } from '../../validation';
import { CountryOptionItem } from '../index';

interface Props {
  type: 'billing' | 'shipping';
  address: Address | null;
  countryOptions: CountryOptionItem[];
  showHeader: boolean;
  shippingInfo?: string;
  updateAddressDetails: (type: 'billing' | 'shipping', field: string, value: string) => void;
}

const AddressInput = ({
  type,
  address,
  countryOptions,
  updateAddressDetails,
  shippingInfo,
  showHeader = true,
}: Props) => {
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
  const { getValues } = useFormContext();

  const getTypedField = (fieldName: string): string => {
    return `${type}.${fieldName}`;
  };

  return (
    <>
      {showHeader && (
        <div className={`${type}-address-header col-span-full pt-2`}>
          <label className="text-base font-medium capitalize text-gray-900 dark:text-light-100">
            {formatCheckoutMessage({ id: `${type}Address`, defaultMessage: `${type} address` })}
          </label>
        </div>
      )}

      <FormFieldInput
        name={getTypedField('firstName')}
        inputAutoComplete="given-name"
        formId="checkout.address"
        value={address?.firstName || ''}
        onChange={(name, value) => updateAddressDetails(type, name.slice(name.indexOf('.') + 1), value)}
        containerClassNames="col-span-6 sm:col-span-6"
        validation={{ required: true }}
      />
      <FormFieldInput
        name={getTypedField('lastName')}
        inputAutoComplete="family-name"
        formId="checkout.address"
        value={address?.lastName || ''}
        onChange={(name, value) => updateAddressDetails(type, name.slice(name.indexOf('.') + 1), value)}
        containerClassNames="col-span-6 sm:col-span-6"
        validation={{ required: true }}
      />

      <FormFieldInput
        name={getTypedField('streetName')}
        inputAutoComplete="given-name"
        formId="checkout.address"
        value={address?.streetName || ''}
        onChange={(name, value) => updateAddressDetails(type, name.slice(name.indexOf('.') + 1), value)}
        containerClassNames="col-span-full sm:col-span-9"
        validation={{ required: true }}
      />
      <FormFieldInput
        name={getTypedField('streetNumber')}
        formId="checkout.address"
        value={address?.streetNumber || ''}
        onChange={(name, value) => updateAddressDetails(type, name.slice(name.indexOf('.') + 1), value)}
        containerClassNames="col-span-full sm:col-span-3"
        validation={{ required: true }}
      />
      <FormFieldInput
        name={getTypedField('postalCode')}
        inputAutoComplete="postal-code"
        formId="checkout.address"
        value={address?.postalCode || ''}
        onChange={(name, value) => updateAddressDetails(type, name.slice(name.indexOf('.') + 1), value)}
        containerClassNames="col-span-full sm:col-span-6"
        validation={{
          required: true,
          validate: (value) => {
            const country = getValues(getTypedField('country')) ?? 'DE';
            return isValidPostalCode(value, country);
          },
        }}
      />
      <FormFieldInput
        name={getTypedField('city')}
        inputAutoComplete="address-level2"
        formId="checkout.address"
        value={address?.city || ''}
        onChange={(name, value) => updateAddressDetails(type, name.slice(name.indexOf('.') + 1), value)}
        containerClassNames="col-span-full sm:col-span-6"
        validation={{ required: true }}
      />
      <FormFieldInput
        name={getTypedField('additionalStreetInfo')}
        inputAutoComplete="additionalStreetInfo"
        formId="checkout.address"
        value={address?.additionalStreetInfo || ''}
        onChange={(name, value) => updateAddressDetails(type, name.slice(name.indexOf('.') + 1), value)}
        containerClassNames="col-span-full sm:col-span-12"
      />
      <FormFieldInput
        name={getTypedField('company')}
        inputAutoComplete="company"
        formId="checkout.address"
        value={address?.company || ''}
        onChange={(name, value) => updateAddressDetails(type, name.slice(name.indexOf('.') + 1), value)}
        containerClassNames="col-span-full sm:col-span-12"
      />
      <FormFieldSelect
        name={getTypedField('country')}
        formId="checkout.address"
        options={countryOptions}
        selectedOptionValue={(address?.country as string) || 'DE'}
        onChange={(name, value) => updateAddressDetails(type, name.slice(name.indexOf('.') + 1), value)}
        containerClassNames="col-span-full sm:col-span-12"
        infoText={shippingInfo}
        validation={{
          required: true,
          deps: getTypedField('postalCode'),
        }}
      />
    </>
  );
};

export default AddressInput;
