import { Cart } from '@Types/cart/Cart';
import { LineItem } from '@Types/cart/LineItem';
import { ShippingMethod } from '@Types/cart/ShippingMethod';
import { ShippingMethodMapping } from '@Types/cart/ShippingMethodMapping';
import { Money } from '@Types/product/Money';
import { CurrencyHelpers } from '../../../../helpers/currencyHelpers';
import { OrderSummaryTotals } from '../types';
import { ShippingHandler } from './ShippingHandler';

export class CartSummaryHandler {
  public static getOrderTotals(
    cart: Cart,
    deliveryCountry: string,
    shippingMethods: ShippingMethod[],
    shippingMethodMap: ShippingMethodMapping,
    itemShippingMap?: Record<string, string>,
  ): OrderSummaryTotals {
    const itemMap: Record<string, string> = {};
    if (cart.lineItems?.length > 0) {
      for (const lineItem of cart.lineItems) {
        const mapped = CartSummaryHandler.getShippingMethodForItem(
          lineItem,
          deliveryCountry,
          shippingMethods,
          shippingMethodMap,
          itemShippingMap,
        );

        if (lineItem.lineItemId && mapped !== null) {
          itemMap[lineItem.lineItemId] = mapped;
        }
      }
    }

    const orderTotals: OrderSummaryTotals = {
      subTotal: CartSummaryHandler.getSubTotal(cart),
      discountTotal: CartSummaryHandler.getDiscountTotal(cart),
      shippingTotal: CartSummaryHandler.getShippingTotal(cart, deliveryCountry, shippingMethods, itemMap),
    };

    orderTotals.orderTotal = CartSummaryHandler.calculateOrderTotal(cart, orderTotals);

    return orderTotals;
  }

  public static getSubTotal(cart: Cart): Money {
    return (cart.lineItems || []).reduce(
      (total, item) => CurrencyHelpers.addCurrency(total, CurrencyHelpers.multiplyCurrency(item.price, item.count)),
      {
        currencyCode: cart.sum?.currencyCode || 'EUR',
        fractionDigits: cart.sum?.fractionDigits || 2,
        centAmount: 0,
      },
    );
  }

  public static getShippingTotal(
    cart: Cart,
    deliveryCountry: string,
    shippingMethods: ShippingMethod[],
    itemMap: object,
  ): Money {
    return (cart.lineItems || []).reduce(
      (total, item) => {
        if (!itemMap[item.lineItemId] || !deliveryCountry) {
          return total;
        }

        const useMethod = shippingMethods.find((method) => method.shippingMethodId === itemMap[item.lineItemId]);
        const rate = ShippingHandler.getShippingRate(useMethod, deliveryCountry)?.price || null;

        if (rate === null) {
          return total;
        }

        return CurrencyHelpers.addCurrency(total, rate);
      },
      {
        currencyCode: cart.sum?.currencyCode || 'EUR',
        fractionDigits: cart.sum?.fractionDigits || 2,
        centAmount: 0,
      },
    );
  }

  public static getDiscountTotal(cart: Cart): Money {
    const appliedDiscounts = cart.discountCodes?.filter(
      (discount) => discount.state === 'MatchesCart' && !!discount.discountedAmount,
    );

    return appliedDiscounts.reduce(
      (total, discount) => CurrencyHelpers.subtractCurrency(total, discount.discountedAmount),
      {
        currencyCode: cart.sum?.currencyCode || 'EUR',
        fractionDigits: cart.sum?.fractionDigits || 2,
        centAmount: 0,
      },
    );
  }

  public static calculateOrderTotal(cart: Cart, summary: OrderSummaryTotals): Money {
    const orderTotal = CurrencyHelpers.addCurrency(
      summary.subTotal || CartSummaryHandler.getSubTotal(cart),
      summary.discountTotal || CartSummaryHandler.getDiscountTotal(cart),
    );
    if (summary.shippingTotal) {
      return CurrencyHelpers.addCurrency(orderTotal, summary.shippingTotal);
    }

    return orderTotal;
  }

  private static getShippingMethodForItem(
    lineItem: LineItem,
    deliveryCountry: string,
    shippingMethods: ShippingMethod[],
    shippingMethodMap: ShippingMethodMapping,
    itemShippingMap?: Record<string, string>,
  ): string | null {
    if (!lineItem.lineItemId) {
      return null;
    }

    if (!!itemShippingMap?.[lineItem.lineItemId]) {
      return itemShippingMap?.[lineItem.lineItemId];
    }

    const method = ShippingHandler.getShippingMethodsForItem(
      lineItem,
      shippingMethods,
      shippingMethodMap,
      deliveryCountry,
    ).find((method) => typeof method !== undefined);

    return method?.shippingMethodId ?? null;
  }
}
