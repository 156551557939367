import { useState, useEffect, FormEvent } from 'react';
import { useRouter } from 'next/router';
import { Cart as CartType } from '@Types/cart/Cart';
import { PaymentLogos } from '@Types/cart/PaymentLogo';
import Spinner from 'components/commercetools-ui/spinner';
import { useFormat } from 'helpers/hooks/useFormat';
import { Reference } from 'helpers/reference';
import { NextFrontasticImage } from 'frontastic/lib/image';
import CartForm from './cart-form';
import EmptyCart from './empty-cart';

export interface Props {
  pageTitle?: string;
  emptyStateImage?: NextFrontasticImage;
  emptyStateTitle?: string;
  emptyStateSubtitle?: string;
  emptyStateCTALabel?: string;
  emptyStateCTALink?: Reference;
  cart: CartType;
  editItemQuantity: (lineItemId: string, newQuantity: number) => Promise<void>;
  removeItem: (lineItemId: string) => void;
  paymentLogos: PaymentLogos[];
  defaultShippingCountry?: string;
  deliveryTime?: string;
}

const Cart = ({
  cart,
  editItemQuantity,
  removeItem,
  pageTitle,
  emptyStateImage,
  emptyStateTitle,
  emptyStateSubtitle,
  emptyStateCTALabel,
  emptyStateCTALink,
  paymentLogos,
  defaultShippingCountry = 'DE',
  deliveryTime,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(true);

  //i18n messages
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });

  const router = useRouter();

  const onCheckout = (e: FormEvent) => {
    e.preventDefault();
    router.push('/checkout');
  };

  const goToProductPage = (_url: string) => router.push(_url);

  useEffect(() => {
    if (cart?.lineItems) {
      setLoading(false);
    }
  }, [cart]);

  if (loading) {
    return (
      <div className="flex items-stretch justify-center py-20 px-12">
        <Spinner />
      </div>
    );
  }

  if ((!loading && !cart?.lineItems) || cart.lineItems.length < 1) {
    return (
      <EmptyCart
        pageTitle={pageTitle}
        image={emptyStateImage}
        title={emptyStateTitle}
        subtitle={emptyStateSubtitle}
        ctaLabel={emptyStateCTALabel}
        ctaLink={emptyStateCTALink}
      />
    );
  }

  return (
    <main className="mx-auto max-w-2xl pt-5 pb-24 sm:px-4 lg:max-w-7xl lg:px-0">
      <div className="lg:grid lg:grid-cols-12 lg:gap-x-10">
        <div className="flex flex-wrap items-center justify-between pb-3 lg:col-span-8">
          <h1 className="flex text-xl font-bold text-neutral-900 dark:text-light-100 sm:text-4xl">
            {formatCartMessage({ id: 'cart.shopping', defaultMessage: 'Shopping Cart' })}
          </h1>
          <span className="text-sm">
            {cart.lineItems.length}{' '}
            {cart.lineItems.length > 1
              ? formatCartMessage({ id: 'cart.shopping.itemsLabel', defaultMessage: 'Items' })
              : formatCartMessage({ id: 'cart.shopping.itemLabel', defaultMessage: 'Item' })}
          </span>
        </div>
      </div>
      <div className="mb-5 text-xs font-light text-blue-600">
        {formatCartMessage({
          id: 'cart.shopping.unique',
          defaultMessage: 'Attention, unique item - wheels are not reserved',
        })}
      </div>
      {loading ? (
        <div className="flex items-stretch justify-center py-10 px-12">
          <Spinner />
        </div>
      ) : (
        <CartForm
          cart={cart}
          submitForm={onCheckout}
          goToProductPage={goToProductPage}
          editItemQuantity={editItemQuantity}
          removeItem={removeItem}
          paymentLogos={paymentLogos}
          defaultShippingCountry={defaultShippingCountry}
          deliveryTime={deliveryTime}
        />
      )}
    </main>
  );
};

export default Cart;
